<template>
  <div>
    <div class="main-content" v-if="!isLoading">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <div class="card-title">Solicitação de Transporte</div>
              <div class="card-title" v-if="entity.status_solicitacao == 2">
                <p>
                  Tempo para realizar a programação: {{ minutos }}:{{
                    segundos < 10 ? "0" : ""
                  }}{{ segundos }}
                </p>
              </div>
              <div class="card-title">
                <button
                  data-toggle="modal"
                  data-target="#modalRAS"
                  target="_blank"
                  class="btn btn-back mr-2"
                  v-if="$store.state.user.perfil_id != 22"
                >
                  <i class="fas fa-file-medical"></i> Vincular RIH
                </button>
                <div
                  class="modal fade"
                  id="modalRAS"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="modalRASTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          Inserir RIH
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="row justify-content-center">
                          <div class="col-md-6">
                            <input type="file" id="rasPic" name="rasPic" />
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Fechar
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-dismiss="modal"
                          @click="salvarRas"
                        >
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-add mr-2"
                  v-if="
                    $store.state.user.perfil_id == 2 ||
                    (entity.status_solicitacao == 3 &&
                      $store.state.user.perfil_id == 21)
                  "
                  data-toggle="modal"
                  data-target="#modalAlterar"
                >
                  Alterar Status
                </button>
                <a :href="entity.url" target="_blank" class="btn btn-back mr-2">
                  <i class="fas fa-file-pdf"></i> Exportar PDF
                </a>
                <button
                  class="btn btn-add mr-2"
                  v-if="
                    $store.state.user.perfil_id == 2 ||
                    (entity.status_solicitacao == 3 &&
                      $store.state.user.perfil_id == 21) ||
                    $store.state.user.perfil_id == 7
                  "
                  data-toggle="modal"
                  data-target="#modalHistoricoMovimentacao"
                >
                  <i class="fas fa-file"></i> Histórico de Alterações
                </button>
              </div>
              <div class="card-title" v-if="entity.flag_recorrencia">
                <span
                  :style="
                    'color:' +
                    (entity.duplicado_recorrencia ? '#8800aa;' : '#aa5588;')
                  "
                >
                  <span
                    class="badge"
                    style="background: #8800aa; border-radius: 100px"
                    v-if="entity.duplicado_recorrencia"
                    >R</span
                  >
                  <span
                    class="badge"
                    style="background: #aa5588; border-radius: 100px"
                    v-if="!entity.duplicado_recorrencia"
                    >1º</span
                  >
                  Solicitação recorrente
                </span>
              </div>
            </div>
            <hr style="margin-top: -4px" />
            <div class="card-body">
              <div
                class="alert alert-warning"
                role="alert"
                style="font-size: 14px; text-align: center"
                v-if="
                  entity.responsavel_programacao_id !== null &&
                  entity.responsavel_programacao_id !==
                    $store.state.user.usuario_id &&
                  entity.status_solicitacao != 4
                "
              >
                Você não pode realizar alterações nesta programação, pois o
                usuário
                <strong>
                  {{
                    entity.responsavel_programacao
                      ? entity.responsavel_programacao.usuario_nome
                      : ""
                  }}
                </strong>
                está responsável por esta programação.
              </div>
              <div
                class="alert alert-warning"
                role="alert"
                style="font-size: 14px; text-align: center"
                v-if="
                  entity.responsavel_programacao_id ==
                  $store.state.user.usuario_id
                "
              >
                <strong>
                  Você está responsável por esta programação, enquanto estiver
                  nesta tela, ninguém terá acesso para realizar
                  modificações.</strong
                >
              </div>
              <div class="col-md-12" v-if="entity.ras">
                <div class="b-card-subtitle">RIH Vinculado:</div>
              </div>
              <div class="form-group image-container mt-1" v-if="entity.ras">
                <table class="table table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 52%">Nome Arquivo</th>
                      <th scope="col" style="width: 23%">Login</th>
                      <th scope="col" style="width: 25%">Baixar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ entity.ras }}
                      </td>
                      <td>
                        {{ entity.usuario_rih ? entity.usuario_rih.login : "" }}
                      </td>
                      <td>
                        <a class="btn btn-add" :href="entity.ras_url"
                          ><i class="fas fa-download"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
              <div class="row mb-2" v-if="!oculta">
                <div class="col-md-12">
                  <div class="b-card-subtitle">
                    Linha do Tempo da Solicitação
                  </div>
                </div>
              </div>
              <div class="row" v-if="!oculta">
                <div
                  class="col-md-12 mb-4"
                  v-if="entity.status_solicitacao != 4"
                >
                  <ol class="progress2" data-steps="4">
                    <li
                      :class="
                        entity.status_solicitacao > 0
                          ? 'done'
                          : entity.status_solicitacao == 0
                          ? 'active'
                          : ''
                      "
                    >
                      <span class="name">Solicitado</span>
                      <span class="step"><span>1</span></span>
                      <span class="step-description">
                        {{
                          entity.data_solicitacao
                            ? entity.data_solicitacao_f
                            : ""
                        }}<br />
                        {{
                          entity.usuario_solicitacao
                            ? entity.usuario_solicitacao.usuario_nome
                            : ""
                        }}
                      </span>
                    </li>
                    <li
                      :class="
                        entity.status_solicitacao > 1
                          ? 'done'
                          : entity.status_solicitacao == 1
                          ? 'active'
                          : ''
                      "
                    >
                      <span class="name">Aprovado</span>
                      <span class="step"><span>2</span></span>
                      <span class="step-description">
                        {{ entity.data_aprovacao ? entity.data_aprovacao_f : ""
                        }}<br />
                        {{
                          entity.usuario_aprovacao
                            ? entity.usuario_aprovacao.usuario_nome
                            : ""
                        }}
                      </span>
                    </li>
                    <li
                      :class="
                        entity.status_solicitacao > 2
                          ? 'done'
                          : entity.status_solicitacao == 2
                          ? 'active'
                          : ''
                      "
                    >
                      <span class="name">Programado</span>
                      <span class="step"><span>3</span></span>
                      <span class="step-description"
                        >{{
                          entity.data_programacao
                            ? entity.data_programacao_f
                            : ""
                        }}<br />
                        {{
                          entity.usuario_programacao
                            ? entity.usuario_programacao.usuario_nome
                            : ""
                        }}</span
                      >
                    </li>
                    <li
                      :class="
                        entity.status_solicitacao > 3
                          ? 'done'
                          : entity.status_solicitacao == 3
                          ? 'active'
                          : ''
                      "
                    >
                      <span class="name">Finalizado</span>
                      <span class="step"><span>4</span></span>
                      <span class="step-description"
                        >{{
                          entity.data_finalizacao
                            ? entity.data_finalizacao_f
                            : ""
                        }}<br />
                        {{
                          entity.usuario_finalizacao
                            ? entity.usuario_finalizacao.usuario_nome
                            : ""
                        }}</span
                      >
                    </li>
                  </ol>
                </div>
                <div class="col-md-12" v-else>
                  <div class="alert alert-danger" role="alert">
                    Esta solicitação foi cancelada por:
                    <strong>{{
                      entity.usuario_cancelamento.usuario_nome
                    }}</strong>
                    em {{ entity.data_cancelamento }}<br /><br />
                    <strong>Justificativa:</strong>
                    {{ entity.justificativa_cancelamento }}<br /><br />
                    <div v-if="entity.cancelamento_id">
                      <strong>Motivo do Cancelamento:</strong>
                      {{ entity.cancelamento.cancelamento_nome }}
                    </div>
                    <div v-else>
                      <strong>Motivo do Cancelamento:</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2 mt-4" v-if="veiculos_enviados.length > 0">
                <div class="col-md-12">
                  <div class="b-card-subtitle">Veículos Enviados</div>
                </div>
              </div>
              <div class="row mb-2" v-if="veiculos_enviados.length > 0">
                <div class="col-md-12">
                  <div
                    class="row mt-1"
                    v-for="veiculo in veiculos_enviados"
                    :key="veiculo.envio_veiculo_id"
                  >
                    <div class="col-md-1 mt-2" v-if="!oculta">
                      <button
                        v-if="
                          (entity.usuario_programacao_id ===
                            $store.state.user.usuario_id ||
                            $store.state.user.perfil_id == 2) &&
                          veiculo.status !== '1'
                        "
                        class="btn btn-crud delete"
                        @click="deletarVeiculo(veiculo)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                    <div
                      :class="[
                        oculta ? 'col-md-4 mt-2' : 'col-md-2 mt-2',
                        veiculo.status === '1' ? 'riscar' : '',
                      ]"
                      :style="
                        oculta ? '' : 'font-weight: bold; margin-left: -50px'
                      "
                    >
                      {{ veiculo.veiculo.nome_veiculo }} -
                      {{ veiculo.veiculo.placa_veiculo }}
                    </div>
                    <div class="col-md-2">
                      <label>Última Posição</label>
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        :value="veiculo.ultima_posicao"
                      />
                    </div>
                    <div class="col-md-2">
                      <label>Data/Hora</label>
                      <input
                        disabled
                        type="datetime-local"
                        class="form-control"
                        :value="veiculo.data_ultima_posicao"
                      />
                    </div>
                    <div
                      class="col-md-2 mt-2"
                      v-if="entity.status_solicitacao != 3 && !oculta"
                    >
                      <button
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modalLancamento"
                        @click="movimentacao.veiculo = veiculo"
                        v-if="
                          veiculo.status !== '1' &&
                          (entity.responsavel_programacao_id === null ||
                            entity.responsavel_programacao_id ===
                              $store.state.user.usuario_id ||
                            $store.state.user.perfil_id == 2)
                        "
                      >
                        Movimentar
                      </button>
                    </div>
                    <div class="col-md-1 mt-1" v-if="!oculta">
                      <button
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modalMovimentacoes"
                        @click="setHistorico(veiculo.posicoes)"
                      >
                        Histórico
                      </button>
                    </div>
                    <div
                      class="col-md-1 mt-1"
                      v-if="!oculta && veiculo.status != null"
                    >
                      <button
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modalDelecoes"
                      >
                        Exclusão
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <!-- <div
                      class="col-md-1"
                      style="border-right: 1px solid #e4e4e4"
                    >
                      <div
                        class="row pd-item"
                        :class="page == 1 ? 'selected' : ''"
                        @click="page = 1"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-id-card"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 2 ? 'selected' : ''"
                        @click="page = 2"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-person-booth"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 3 ? 'selected' : ''"
                        @click="page = 3"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-tasks"></i>
                        </div>
                      </div>
                      <div
                        class="row pd-item"
                        :class="page == 4 ? 'selected' : ''"
                        @click="page = 4"
                      >
                        <div class="col-md-12">
                          <i class="fas fa-stethoscope"></i>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-12">
                      <section>
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações da Solicitação
                            </div>
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="
                            configuracoes.classificacao == 1 &&
                            entity.classificacao
                          "
                        >
                          <div class="col-md-3">
                            <label>Classificação da ocorrência</label>

                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.classificacao.descricao"
                            />
                          </div>

                          <div
                            class="mt-2 classificacao"
                            :style="'background: ' + entity.classificacao.cor"
                          ></div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-3">
                            <label>Número da Solicitação</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.numero_solicitacao"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Data e Hora da Solicitação</label>
                            <input
                              disabled
                              type="datetime-local"
                              class="form-control"
                              v-model="entity.data_solicitacao"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Data e Hora do Agendamento</label>
                            <input
                              disabled
                              type="datetime-local"
                              class="form-control"
                              v-model="entity.data_procedimento"
                            />
                          </div>

                          <div class="col-md-3">
                            <label>Tipo de Solicitação</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.tipo_transporte.tipo_transporte_nome
                              "
                            />
                          </div>
                        </div>
                        <div class="row mt-1 html2pdf__page-break">
                          <div class="col-md-12">
                            <label>Motivo da Solicitação</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.motivo_transporte.motivo_transporte_nome
                              "
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-12">
                            <label>Observações do Transporte</label>
                            <textarea
                              disabled
                              class="form-control"
                              cols="30"
                              rows="3"
                              v-model="entity.observacao_transporte"
                            ></textarea>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <toggle-button
                              v-model="entity.flag_mandado_judicial"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            É mandado judicial?
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.flag_mandado_judicial"
                        >
                          <div class="col-md-3">
                            <label
                              ><span class="required">*</span> Número do
                              processo</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_numero_processo"
                              disabled
                            />
                          </div>

                          <div class="col-md-3">
                            <label>Data da liberação da vaga</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.mj_data_liberacao_vaga_f"
                              disabled
                            />
                          </div>
                          <div class="col-md-3">
                            <label> Data da sentença</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="entity.mj_data_sentenca"
                              disabled
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Juiz</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_juiz"
                              disabled
                            />
                          </div>
                          <div class="col-md-12 mt-1">
                            <label> Resumo da Sentença</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.mj_resumo_sentenca"
                              disabled
                            />
                          </div>
                        </div>

                        <hr />

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <toggle-button
                              v-model="entity.flag_vaga_zero"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            É vaga zero?
                          </div>
                        </div>

                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Solicitante</div>
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="
                            configuracoes.setor_solicitacao == 1 &&
                            configuracoes.telefone_solicitacao == 1 &&
                            configuracoes.profissional_solicitacao == 1
                          "
                        >
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Responsável pela
                              abertura da solicitação</label
                            >
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.profissional_nome"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.solicitante.unidade_solicitante_setor
                              "
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                              v-model="entity.solicitante.telefone"
                            />
                          </div>
                          <!-- <div class="col-md-4" @click="getCbo">
                            <label>CBO</label>
                            <v-select
                              @click="getCbo"
                              :options="cbos"
                              label="cbo_nome"
                              v-model="entity.cbo_id"
                              :reduce="(cbo) => cbo.cbo_id"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de CBO's
                              </div>
                            </v-select>
                          </div> -->
                          <div class="col-md-4">
                            <label>Nome do médico solicitante</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.medico_solicitante"
                            />
                          </div>
                          <!-- <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.id_funcional"
                            />
                          </div> -->
                          <div class="col-md-4">
                            <label>Número do Conselho</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.numero_conselho"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Conselho de Classe</label>
                            <v-select
                              disabled
                              :options="conselho_classe"
                              label="nome"
                              v-model="entity.solicitante.conselho_classe"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Unidade Solicitante</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              :value="
                                entity.solicitante.unidade
                                  ? entity.solicitante.unidade
                                      .unidade_saude_nome
                                  : ''
                              "
                            />
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="
                            configuracoes.setor_solicitacao == 0 &&
                            configuracoes.telefone_solicitacao == 0 &&
                            configuracoes.profissional_solicitacao == 0
                          "
                        >
                          <div class="col-md-4">
                            <label
                              ><span class="required">*</span> Nome do
                              Profissional</label
                            >
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              v-model="entity.solicitante.profissional_nome"
                            />
                          </div>
                          <div
                            class="col-md-4"
                            v-if="entity.solicitante.cbo_id"
                          >
                            <label>CBO</label>
                            <input
                              v-if="entity.solicitante.cbo"
                              type="text"
                              disabled
                              class="form-control"
                              v-model="entity.solicitante.cbo.cbo_nome"
                            />
                            <input
                              v-else
                              type="text"
                              disabled
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>CNS</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.profissional_cns"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>ID Funcional</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.id_funcional"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Número do Conselho</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.solicitante.numero_conselho"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Conselho de Classe</label>
                            <v-select
                              disabled
                              :options="conselho_classe"
                              label="nome"
                              v-model="entity.solicitante.conselho_classe"
                            >
                              <div slot="no-options">
                                Aguarde, carregando lista de conselhos
                              </div>
                            </v-select>
                          </div>
                          <div class="col-md-6 mt-1">
                            <label>Unidade Solicitante</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              :value="
                                entity.solicitante.unidade
                                  ? entity.solicitante.unidade
                                      .unidade_saude_nome
                                  : ''
                              "
                            />
                          </div>
                          <div class="col-md-3 mt-1">
                            <label>Setor</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="
                                entity.solicitante.unidade_solicitante_setor
                              "
                            />
                          </div>
                          <div class="col-md-3 mt-1">
                            <label>Telefone</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                              v-model="entity.solicitante.telefone"
                            />
                          </div>
                        </div>
                        <div class="html2pdf__page-break"></div>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Origem
                            </div>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Tipo de Endereço</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.origem.tipo_estabelecimento_id"
                            >
                              <option
                                v-for="tipo in tipos_domicilio"
                                :key="tipo.tipo_domicilio_id"
                                :value="tipo.tipo_domicilio_id"
                              >
                                {{ tipo.tipo_domicilio_nome }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.origem.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-6">
                            <label>Unidade de Origem</label>
                            <input
                              type="text"
                              v-model="entity.origem.estabelecimento_nome"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP da Origem</label>
                            <input
                              disabled
                              v-model="entity.origem.cep"
                              type="tel"
                              class="form-control"
                              v-mask="'99999-999'"
                            />
                          </div>
                          <div class="col-md-5">
                            <label>Logradouro</label>
                            <input
                              disabled
                              v-model="entity.origem.endereco"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-2">
                            <label>Número</label>
                            <input
                              disabled
                              id="inputNumero"
                              v-model="entity.origem.numero"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input
                              disabled
                              v-model="entity.origem.bairro"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label>Estado</label>
                            <v-select
                              disabled
                              :options="ufs"
                              :reduce="(ufs) => ufs.id"
                              :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioOrigem"
                              v-model="entity.origem.estado_id"
                              class="vselect"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Município</label>
                            <v-select
                              disabled
                              :options="municipios_origem"
                              :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'"
                              v-model="entity.origem.municipio_id"
                              class="vselect"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input
                              disabled
                              v-model="entity.origem.complemento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input
                              disabled
                              v-model="entity.origem.ponto_referencia"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="entity.origem.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                        </div>

                        <div
                          class="row"
                          v-if="configuracoes.dificuldade_acesso"
                        >
                          <div class="col-md-6 mt-1">
                            <label
                              ><span class="required">*</span> Possui
                              dificuldade de acesso ao local?</label
                            >
                            <select
                              class="form-control"
                              v-model="entity.origem.dificuldade_acesso"
                              disabled
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="entity.origem.dificuldade_acesso == 'SIM'"
                          >
                            <label><span class="required">*</span> Qual?</label>
                            <input
                              v-model="
                                entity.origem.dificuldade_acesso_descricao
                              "
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.origem.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              v-model="entity.origem.setor"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input
                              v-model="entity.origem.pavimento"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input
                              v-model="entity.origem.leito"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="html2pdf__page-break"></div>
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Endereço de Destino
                            </div>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Tipo de Endereço</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.destino.tipo_estabelecimento_id"
                            >
                              <option
                                disabled
                                v-for="tipo in tipos_domicilio"
                                :key="tipo.tipo_domicilio_id"
                                :value="tipo.tipo_domicilio_id"
                              >
                                {{ tipo.tipo_domicilio_nome }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label>Tipo de Destino</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.tipo_destino"
                            >
                              <option value="Municipal">Municipal</option>
                              <option value="Intermunicipal">
                                Intermunicipal
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Unidade de Destino</label>
                            <input
                              type="text"
                              v-model="entity.destino.estabelecimento_nome"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-2">
                            <label>CEP do Destino</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              id="cepUnidade"
                              v-mask="'99999-999'"
                              v-model="entity.destino.cep"
                            />
                          </div>
                          <div class="col-md-5">
                            <label>Logadouro</label>
                            <input
                              disabled
                              v-model="entity.destino.endereco"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-2">
                            <label>Número</label>
                            <input
                              disabled
                              id="inputNumeroDestino"
                              v-model="entity.destino.numero"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-3">
                            <label>Bairro</label>
                            <input
                              disabled
                              v-model="entity.destino.bairro"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-2 mt-1">
                            <label>Estado</label>
                            <v-select
                              disabled
                              :options="ufs"
                              :reduce="(ufs) => ufs.id"
                              :label="'uf_nome'"
                              v-on:input="getEstadoMunicipioDestino"
                              v-model="entity.destino.estado_id"
                              class="vselect"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Município</label>
                            <v-select
                              disabled
                              :options="municipios_destino"
                              :reduce="(municipios) => municipios.id"
                              :label="'municipio_nome'"
                              v-model="entity.destino.municipio_id"
                              class="vselect"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 mt-1">
                            <label>Complemento</label>
                            <input
                              disabled
                              v-model="entity.destino.complemento"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Ponto de Referência</label>
                            <input
                              disabled
                              v-model="entity.destino.ponto_referencia"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4 mt-1">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="entity.destino.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                        </div>

                        <div
                          class="row mt-1"
                          v-if="entity.destino.tipo_estabelecimento_id == 6"
                        >
                          <div class="col-md-4">
                            <label>Setor</label>
                            <input
                              v-model="entity.destino.setor"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Pavimento</label>
                            <input
                              v-model="entity.destino.pavimento"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Leito</label>
                            <input
                              v-model="entity.destino.leito"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label
                              >Nome do Profissional Responsável pela
                              Autorização</label
                            >
                            <input
                              v-model="
                                entity.destino.nome_profissional_autorizacao
                              "
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                          <div class="col-md-6">
                            <label>SER/SISREG</label>
                            <input
                              v-model="entity.destino.cer_sisgero"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </div>
                        </div>

                        <div class="row mt-1">
                          <div class="col-md-6">
                            <div class="custom-control custom-switch">
                              <input
                                disabled
                                autocomplete="new-password"
                                v-model="entity.flag_retorna_origem"
                                placeholder=""
                                type="checkbox"
                                class="custom-control-input"
                                id="customSwitch1"
                              />
                              <label
                                class="custom-control-label"
                                for="customSwitch1"
                                >Retorna para Origem?</label
                              >
                            </div>
                          </div>
                        </div>
                      </section>

                      <div class="html2pdf__page-break"></div>

                      <section
                        v-if="entity.tipo_transporte.tipo_solicitacao == 1"
                      >
                        <div class="row mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Materiais para Transporte
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">
                                    Código e Nome
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="material in entity.material.materiais"
                                  :key="material.produto_id"
                                >
                                  <td>
                                    {{ material.produto_id }} -
                                    {{ material.produto_nome }}
                                  </td>
                                </tr>
                                <tr
                                  v-if="entity.material.materiais.length == 0"
                                  align="center"
                                >
                                  <td>Nenhum material vinculado.</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Cuidados com o Transporte
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-6">
                            <div
                              class="row"
                              v-for="(tc, index) in tipos_cuidado"
                              :key="tc.tipo_cuidado_id"
                              style="font-size: 14px"
                            >
                              <div class="col-md-12">
                                <div class="form-check">
                                  <input
                                    disabled
                                    v-model="tipos_cuidado[index].value"
                                    class="form-check-input"
                                    type="checkbox"
                                    value="true"
                                    :id="tc.tipo_cuidado_id"
                                  />
                                  <label
                                    class="form-check-label"
                                    :for="tc.tipo_cuidado_id"
                                    style="font-size: 16px"
                                  >
                                    {{ tc.tipo_cuidado_nome }}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        v-if="entity.tipo_transporte.tipo_solicitacao != 1"
                      >
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Informações do Paciente
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label>CPF</label>
                            <input
                              disabled
                              :value="
                                entity.paciente ? entity.paciente.cpf : ''
                              "
                              type="text"
                              class="form-control"
                              v-mask="'999.999.999-99'"
                            />
                          </div>
                          <div class="col-md-6">
                            <label>CNS</label>
                            <input
                              disabled
                              :value="
                                entity.paciente ? entity.paciente.cns : ''
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label>Nome</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_paciente"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Nome Social</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_social"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Gênero</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.paciente.sexo"
                            >
                              <option value="M">MASCULINO</option>
                              <option value="F">FEMININO</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <label>Data de Nascimento</label>
                            <input
                              disabled
                              v-model="entity.paciente.data_nascimento"
                              type="date"
                              class="form-control"
                              v-if="
                                entity.paciente.data_nascimento !== '1970-01-01'
                              "
                            />
                            <input
                              disabled
                              value="Não Informado"
                              type="text"
                              class="form-control"
                              v-else
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="entity.paciente.telefone"
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Cor/Raça</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.paciente.raca"
                            >
                              <option value="BRANCA">BRANCA</option>
                              <option value="PRETA">PRETA</option>
                              <option value="PARDA">PARDA</option>
                              <option value="AMARELA">AMARELA</option>
                              <option value="INDÍGENA">INDÍGENA</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mt-1">
                          <div class="col-md-6">
                            <label>Nome do Pai</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_pai"
                              type="tel"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-6">
                            <label>Nome da Mãe</label>
                            <input
                              disabled
                              v-model="entity.paciente.nome_mae"
                              type="tel"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label
                              >Possui dificuldade de acesso ao entrar ou sair do
                              domicílio?</label
                            >
                            <select
                              disabled
                              class="form-control"
                              v-model="
                                entity.paciente.dificuldade_acesso_domicilio
                              "
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="
                              entity.paciente.dificuldade_acesso_domicilio ==
                              'SIM'
                            "
                          >
                            <label>Qual?</label>
                            <input
                              disabled
                              v-model="
                                entity.paciente
                                  .dificuldade_acesso_domicilio_descricao
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label
                              >Possui dificuldade de acesso ao entrar ou sair do
                              paciente?</label
                            >
                            <select
                              disabled
                              class="form-control"
                              v-model="
                                entity.paciente.dificuldade_acesso_paciente
                              "
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="
                              entity.paciente.dificuldade_acesso_paciente ==
                              'SIM'
                            "
                          >
                            <label>Qual?</label>
                            <input
                              disabled
                              v-model="
                                entity.paciente
                                  .dificuldade_acesso_paciente_descricao
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-1">
                            <label>Paciente possui acompanhante?</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.possui_acompanhante"
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-6 mt-1"
                            v-if="entity.possui_acompanhante == 'SIM'"
                          >
                            <label>Tipo do Acompanhante</label>
                            <input
                              disabled
                              :value="
                                entity.acompanhante.tipo_acompanhante
                                  ? entity.acompanhante.tipo_acompanhante
                                      .tipo_acompanhante_nome
                                  : ''
                              "
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div
                          class="row mt-1"
                          v-if="entity.possui_acompanhante == 'SIM'"
                        >
                          <div class="col-md-4">
                            <label>Nome</label>
                            <input
                              disabled
                              v-model="entity.acompanhante.acompanhante_nome"
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Telefone</label>
                            <input
                              disabled
                              v-model="
                                entity.acompanhante.acompanhante_telefone
                              "
                              type="tel"
                              class="form-control"
                              v-mask="'(99) 99999-9999'"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>CPF</label>
                            <input
                              disabled
                              :value="
                                entity.acompanhante
                                  ? entity.acompanhante.acompanhante_cpf
                                  : ''
                              "
                              type="tel"
                              class="form-control"
                              v-mask="'999.999.999-99'"
                            />
                          </div>
                        </div>
                      </section>

                      <div class="row mb-2 mt-3">
                        <div class="col-md-12">
                          <div class="b-card-subtitle">
                            Vtr Tripulada pelo Médico
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <toggle-button
                          v-model="entity.flag_medico_unidade"
                          color="#15488A"
                          :height="25"
                          disabled
                        />
                        Vtr será tripulada pelo médico da unidade hospitalar?
                      </div>
                      <div class="row mt-1" v-if="entity.flag_medico_unidade">
                        <div class="col-md-6">
                          <label
                            ><span class="required">*</span> Nome do médico da
                            unidade</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="entity.medico_unidade_tripulado"
                            disabled
                          />
                        </div>

                        <div class="col-md-6">
                          <label>CRM do médico da unidade</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="entity.crm_medico_tripulado"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="row mb-2 mt-1">
                        <div class="col-md-12">
                          <div class="b-card-subtitle">
                            Data de de entrada na unidade solicitante
                          </div>
                        </div>
                      </div>
                      <div
                        class="row mb-1"
                        v-if="configuracoes.sinais_vitais == 1"
                      >
                        <div class="col-md-4" v-if="entity.paciente">
                          <label for="">Data de entrada</label>
                          <input
                            type="datetime-local"
                            class="form-control"
                            v-model="entity.paciente.data_entrada_unidade"
                            max="9999-12-31"
                            disabled
                          />
                        </div>
                      </div>

                      <div class="html2pdf__page-break"></div>
                      <section
                        v-if="entity.tipo_transporte.tipo_solicitacao != 1"
                      >
                        <div class="row mt-3 mb-2">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Condições Clínicas do Paciente
                            </div>
                          </div>
                        </div>

                        <div
                          class="row mt-3"
                          v-if="!configuracoes.sinais_vitais"
                        >
                          <div class="col-md-12">
                            <h3>Antropometria</h3>
                          </div>

                          <div class="col-md-2">
                            <label for="">Peso</label>
                            <input
                              type="number"
                              class="form-control"
                              id=""
                              v-model="entity.paciente.peso"
                              disabled
                            />
                          </div>
                          <div class="col-md-2">
                            <label for="">Altura</label>
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              v-model="entity.paciente.altura"
                              v-mask="'#.##'"
                              disabled
                            />
                          </div>
                          <div class="col-md-2">
                            <label for="">IMC</label>
                            <input
                              disabled
                              type="text"
                              class="form-control"
                              :value="imc ? imc : ''"
                            />
                          </div>
                          <div class="col-ml-1" style="margin-top: 5px">
                            <table class="tabela-imc">
                              <tr>
                                <th
                                  v-if="
                                    entity.paciente.peso &&
                                    entity.paciente.altura &&
                                    imc
                                  "
                                >
                                  Classificação
                                </th>
                                <th
                                  v-if="
                                    entity.paciente.peso &&
                                    entity.paciente.altura &&
                                    imc
                                  "
                                >
                                  IMC
                                </th>
                              </tr>
                              <tr
                                style="
                                  background-color: #ffff00;
                                  color: #000000;
                                "
                                v-if="
                                  imc < 18.5 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Abaixo do peso</td>
                                <td>Menor que 18,5</td>
                              </tr>
                              <tr
                                style="
                                  background-color: #add8e6;
                                  color: #000000;
                                "
                                v-if="
                                  imc >= 18.5 &&
                                  imc <= 24.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Peso normal</td>
                                <td>18,5 - 24,9</td>
                              </tr>
                              <tr
                                style="
                                  background-color: #ffa500;
                                  color: #000000;
                                "
                                v-if="
                                  imc >= 25 &&
                                  imc <= 29.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Sobrepeso</td>
                                <td>25 - 29,9</td>
                              </tr>
                              <tr
                                style="
                                  background-color: #ff4500;
                                  color: #000000;
                                "
                                v-if="
                                  imc >= 30 &&
                                  imc <= 34.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Obesidade grau 1</td>
                                <td>30 - 34,9</td>
                              </tr>
                              <tr
                                style="
                                  background-color: #ff0000;
                                  color: #000000;
                                "
                                v-if="
                                  imc >= 35 &&
                                  imc <= 39.9 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Obesidade grau 2</td>
                                <td>35 - 39,9</td>
                              </tr>
                              <tr
                                style="
                                  background-color: #e30505;
                                  color: #000000;
                                "
                                v-if="
                                  imc >= 40 &&
                                  entity.paciente.peso &&
                                  entity.paciente.altura
                                "
                              >
                                <td>Obesidade grau 3</td>
                                <td>Maior ou igual a 40</td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-md-12 mt-1">
                            <span
                              v-if="imc >= 30"
                              style="font-weight: bold; color: red"
                              >*Indicação de Transporte Bariatrico</span
                            >
                          </div>
                        </div>

                        <div
                          class="row mb-1"
                          v-if="configuracoes.sinais_vitais == 1"
                        >
                          <div class="col-md-4">
                            <label for="">Estado Neurológico</label>
                            <select
                              class="form-control"
                              v-model="entity.paciente.estado_neurologico"
                              disabled
                            >
                              <option value="Alerta">Alerta</option>
                              <option value="Sonolento">Sonolento</option>
                              <option value="Torporoso">Torporoso</option>
                              <option value="Sedado">Sedado</option>
                              <option value="Inconsciente">Inconsciente</option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label for="">Escala de Glasgow</label>
                            <input
                              data-toggle="modal"
                              data-target="#glasgowModalCenter"
                              readonly
                              type="text"
                              class="form-control"
                              id=""
                              v-model="glasgowCount"
                            />
                          </div>
                          <div class="col-md-4" style="margin-top: 30px">
                            <span v-html="glasgowLabel"></span>
                          </div>
                        </div>

                        <div
                          class="row mt-4"
                          v-if="configuracoes.sinais_vitais == 1"
                        >
                          <div class="col-md-12">
                            <h3>Sinais vitais</h3>
                          </div>
                          <div class="col-md-12 mt-1">
                            <div class="row">
                              <div class="col-md-4 bold">Pressão arterial</div>
                              <div class="col-md-2 bold">FC</div>
                              <div class="col-md-2 bold">FR</div>
                              <div class="col-md-2 bold">Temperatura</div>
                              <div class="col-md-2 bold"></div>
                            </div>
                            <div class="row">
                              <div class="col-md-2">
                                <label for="pa_sistolica">Sist. (mmHg):</label>
                                <input
                                  type="number"
                                  id="pa_sistolica"
                                  v-model.number="entity.paciente.pa_sistolica"
                                  class="form-control"
                                  placeholder="Máximo de 300"
                                  disabled
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">Diast. (mmHg)</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  v-model="entity.paciente.pa_diastolica"
                                  placeholder="Máximo de 300"
                                  disabled
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">(bpm)</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder="Máximo de 300"
                                  v-model="entity.paciente.fc"
                                  disabled
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">(rpm)</label>
                                <input
                                  type="number"
                                  disabled
                                  placeholder="Máximo de 300"
                                  class="form-control"
                                  v-model="entity.paciente.fr"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">C°</label>
                                <input
                                  type="number"
                                  disabled
                                  class="form-control"
                                  v-model="entity.paciente.temperatura"
                                  placeholder="Máximo de 60"
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-2">
                                <label for="">Saturação</label>
                                <input
                                  type="number"
                                  disabled
                                  placeholder="Máximo de 100"
                                  class="form-control"
                                  v-model="entity.paciente.saturacao"
                                />
                              </div>
                              <div class="col-md-5" style="margin-top: 30px">
                                <span
                                  class="boxSelecionavel"
                                  :class="
                                    entity.paciente.saturacao_tipo ==
                                    'Ar Atmosférico'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Ar Atmosférico</span
                                >
                                <span
                                  class="boxSelecionavel"
                                  :class="
                                    entity.paciente.saturacao_tipo ==
                                    'Em terapia de O2'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Em terapia de O2</span
                                >
                              </div>
                            </div>
                            <div
                              class="row mt-1"
                              v-if="configuracoes.sinais_vitais == 1"
                            >
                              <div class="col-md-2">
                                <label for="">Glicemia</label>
                                <input
                                  type="number"
                                  placeholder="Máximo de 1000"
                                  class="form-control"
                                  v-model="entity.paciente.glicemia"
                                  disabled
                                />
                              </div>
                              <div class="col-md-3" style="margin-top: 30px">
                                <span
                                  class="boxSelecionavel"
                                  :class="
                                    entity.paciente.glicemia_tipo == 'Em Jejum'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Em Jejum</span
                                >
                                <span
                                  class="boxSelecionavel"
                                  :class="
                                    entity.paciente.glicemia_tipo ==
                                    'Pós-prandial'
                                      ? 'ativo'
                                      : ''
                                  "
                                  >Pós-prandial</span
                                >
                              </div>
                            </div>
                            <div class="row mt-2" v-if="diffIdadeDias <= 29">
                              <div class="col-md-2">
                                <label for="">APGAR (1 minuto)</label>
                                <input
                                  type="number"
                                  disabled
                                  placeholder="Máximo de 10"
                                  class="form-control"
                                  v-model="entity.paciente.apgar_1"
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">APGAR (5 minutos)</label>
                                <input
                                  type="number"
                                  disabled
                                  placeholder="Máximo de 10"
                                  class="form-control"
                                  v-model="entity.paciente.apgar_2"
                                />
                              </div>
                            </div>
                            <div class="row mt-2" v-if="diffIdadeDias <= 29">
                              <div class="col-md-2">
                                <label for="">Idade Gestacional</label>
                                <input
                                  type="number"
                                  disabled
                                  placeholder="Máximo de 45"
                                  class="form-control"
                                  v-model="entity.paciente.idade_gestacional"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-12">
                                <h3>Antropometria</h3>
                              </div>

                              <div class="col-md-2">
                                <label for="">Peso</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id=""
                                  v-model="entity.paciente.peso"
                                  disabled
                                />
                              </div>
                              <div class="col-md-2">
                                <label for="">Altura</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id=""
                                  v-model="entity.paciente.altura"
                                  disabled
                                  v-mask="'#.##'"
                                />
                              </div>
                              <div class="col-md-2 mt-2">
                                <toggle-button
                                  v-model="
                                    entity.paciente.transporte_bariatrico
                                  "
                                  color="#15488A"
                                  :height="25"
                                  disabled
                                />
                                Precisa de Transporte Bariatrico?
                              </div>
                              <!-- <div class="col-md-2">
                                <label for="">IMC</label>
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  :value="imc ? imc : ''"
                                />
                              </div> -->
                              <!-- <div class="col-ml-1" style="margin-top: 5px">
                                <table class="tabela-imc">
                                  <tr>
                                    <th
                                      v-if="
                                        entity.paciente.peso &&
                                        entity.paciente.altura &&
                                        imc
                                      "
                                    >
                                      Classificação
                                    </th>
                                    <th
                                      v-if="
                                        entity.paciente.peso &&
                                        entity.paciente.altura &&
                                        imc
                                      "
                                    >
                                      IMC
                                    </th>
                                  </tr>
                                  <tr
                                    style="
                                      background-color: #ffff00;
                                      color: #000000;
                                    "
                                    v-if="
                                      imc < 18.5 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Abaixo do peso</td>
                                    <td>Menor que 18,5</td>
                                  </tr>
                                  <tr
                                    style="
                                      background-color: #add8e6;
                                      color: #000000;
                                    "
                                    v-if="
                                      imc >= 18.5 &&
                                      imc <= 24.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Peso normal</td>
                                    <td>18,5 - 24,9</td>
                                  </tr>
                                  <tr
                                    style="
                                      background-color: #ffa500;
                                      color: #000000;
                                    "
                                    v-if="
                                      imc >= 25 &&
                                      imc <= 29.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Sobrepeso</td>
                                    <td>25 - 29,9</td>
                                  </tr>
                                  <tr
                                    class="obesidade1"
                                    v-if="
                                      imc >= 30 &&
                                      imc <= 34.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Obesidade grau 1</td>
                                    <td>30 - 34,9</td>
                                  </tr>
                                  <tr
                                    class="obesidade2"
                                    v-if="
                                      imc >= 35 &&
                                      imc <= 39.9 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Obesidade grau 2</td>
                                    <td>35 - 39,9</td>
                                  </tr>
                                  <tr
                                    class="obesidade3"
                                    v-if="
                                      imc >= 40 &&
                                      entity.paciente.peso &&
                                      entity.paciente.altura
                                    "
                                  >
                                    <td>Obesidade grau 3</td>
                                    <td>Maior ou igual a 40</td>
                                  </tr>
                                </table>
                              </div>
                              <div class="col-md-12 mt-1">
                                <span
                                  v-if="imc >= 30"
                                  style="font-weight: bold; color: red"
                                  >*Indicação de Transporte Bariatrico</span
                                >
                              </div> -->
                            </div>
                            <div class="html2pdf__page-break"></div>
                            <div class="row mt-4">
                              <div class="col-md-4">
                                <label for="">Suporte de O²</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.suporte_o2"
                                  disabled
                                >
                                  <option value="Catete Tipo Óculos">
                                    Catete Tipo Óculos
                                  </option>
                                  <option value="Mascara Reservatório">
                                    Mascara Reservatório
                                  </option>
                                  <option value="V.M">V.M</option>
                                  <option value="HOOD">HOOD</option>
                                  <option value="Cpap Nasal">Cpap Nasal</option>
                                </select>
                              </div>
                              <div class="col-md-4">
                                <label for="">Quantos litros de O²?</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="entity.paciente.acesso_venoso_outros"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label for="">Tipos de Acesso Venoso</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.acesso_venoso"
                                  disabled
                                >
                                  <option value="MSD">MSD</option>
                                  <option value="MSE">MSE</option>
                                  <option value="Jugular">Jugular</option>
                                  <option value="Subclávia">Subclávia</option>
                                  <option value="Cefálica">Cefálica</option>
                                  <option value="Umbilical">Umbilical</option>
                                  <option value="Acesso Femoral">
                                    Acesso Femoral
                                  </option>
                                  <option value="Outros">Outros</option>
                                </select>
                              </div>
                              <div
                                class="col-md-8"
                                v-if="entity.paciente.acesso_venoso == 'Outros'"
                              >
                                <label for="">Qual?</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="entity.paciente.acesso_venoso_outros"
                                  disabled
                                />
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div class="col-md-4">
                                <toggle-button
                                  v-model="entity.paciente.incubadora"
                                  color="#15488A"
                                  :height="25"
                                  disabled
                                />
                                Transporte em Incubadora?
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-3">
                                <toggle-button
                                  v-model="entity.paciente.flg_bomba_infusora"
                                  color="#15488A"
                                  :height="25"
                                  disabled
                                />
                                Uso de Bomba Infusora?
                              </div>
                              <div
                                class="col-md-4"
                                v-if="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Quantidade</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.bomba_infusora"
                                  disabled
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Medicamentos</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.bomba_infusora_medicamentos
                                  "
                                  disabled
                                />
                              </div>
                              <div
                                class="col-md-12"
                                v-show="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">ML/H</label>
                                <input
                                  class="form-control"
                                  v-model="entity.paciente.mlh"
                                  type="text"
                                  autocomplete="new-password"
                                  disabled
                                />
                              </div>
                              <div
                                class="col-md-12 mt-1"
                                v-show="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Outras Drogas</label>
                                <textarea
                                  v-model="entity.paciente.outras_drogas"
                                  class="form-control"
                                  id=""
                                  cols="20"
                                  rows="3"
                                ></textarea>
                              </div>
                              <div
                                class="col-md-12 mt-1"
                                v-show="entity.paciente.flg_bomba_infusora"
                              >
                                <label for="">Velocidade de Infusão</label>
                                <input
                                  class="form-control"
                                  v-model="entity.paciente.velocidade_infusao"
                                  type="text"
                                  autocomplete="new-password"
                                  disabled
                                />
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-4">
                                <label>Outros Dispositivos</label>
                                <select
                                  class="form-control"
                                  v-model="entity.paciente.outros_dispositivos"
                                  disabled
                                >
                                  <option value="Cateter Vesical">
                                    Cateter Vesical
                                  </option>
                                  <option value="Cateter Nasogástrico">
                                    Cateter Nasogástrico
                                  </option>
                                  <option value="Cateter Umbilical ">
                                    Cateter Umbilical
                                  </option>
                                  <option value="Dreno">Dreno</option>
                                  <option value="Ostomia">Ostomia</option>
                                  <option value="PICC">PICC</option>
                                  <option value="Todos">Todos</option>
                                </select>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-md-2">
                                <toggle-button
                                  v-model="entity.paciente.flg_alergias"
                                  color="#15488A"
                                  :height="25"
                                  disabled
                                />
                                Alergias
                              </div>
                              <div
                                class="col-md-8"
                                v-if="entity.paciente.flg_alergias"
                              >
                                <label for="">Qual?</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="entity.paciente.alergias"
                                  disabled
                                />
                              </div>
                            </div>
                            <!-- <div class="row mt-2">
                              <div class="col-md-2">
                                <toggle-button
                                  v-model="entity.paciente.covid"
                                  color="#15488A"
                                  :height="25"
                                  disabled
                                />
                                Covid-19
                              </div>
                            </div>
                            <div class="row mt-2" v-if="entity.paciente.covid">
                              <div class="col-md-2">
                                <label for="">Isolamento</label>
                                <div class="form-check">
                                  <input
                                    v-model="
                                      entity.paciente.covid_isolamento_contato
                                    "
                                    class="form-check-input"
                                    type="checkbox"
                                    value="true"
                                    id="Contato"
                                    disabled
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Contato"
                                    style="font-size: 16px"
                                  >
                                    Contato
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    v-model="
                                      entity.paciente
                                        .covid_isolamento_respiratorio
                                    "
                                    class="form-check-input"
                                    type="checkbox"
                                    value="true"
                                    id="Respiratório"
                                    disabled
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Respiratório"
                                    style="font-size: 16px"
                                  >
                                    Respiratório
                                  </label>
                                </div>
                              </div>
                            </div> -->

                            <div
                              class="row mt-2"
                              v-if="configuracoes.sinais_vitais == 1"
                            >
                              <div class="col-md-6">
                                <label>Precauções</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  v-model="entity.paciente.precaucao"
                                />
                              </div>
                            </div>
                            <!-- <div class="col-md-3">
                                <label for="">Acesso Venoso Periférico</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_periferico
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Acesso Venoso Profundo</label>
                                <input
                                  type="number"
                                  placeholder="Local"
                                  class="form-control"
                                  v-model="
                                    entity.paciente.acesso_venoso_profundo
                                  "
                                />
                              </div>
                              <div class="col-md-3">
                                <label for="">Bomba Infusora</label>
                                <input
                                  type="number"
                                  placeholder="Quantidade"
                                  class="form-control"
                                  v-model="entity.paciente.bomba_infusora"
                                />
                              </div>
                            </div> -->
                            <!-- <div class="row mt-2">
                              <div class="col-md-3">
                                <label for="">Escala de Glasgow</label>
                                <input
                                  data-toggle="modal"
                                  data-target="#glasgowModalCenter"
                                  readonly
                                  type="text"
                                  class="form-control"
                                  id=""
                                  v-model="glasgowCount"
                                />
                              </div>
                              <div class="col-md-9" style="margin-top: 30px">
                                <span v-html="glasgowLabel"></span>
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div class="html2pdf__page-break"></div>

                        <div
                          class="row mb-2 mt-3"
                          v-if="entity.paciente.ficha_clinica.length > 0"
                        >
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Ficha Clínica</div>
                          </div>
                        </div>
                        <div
                          class="row mt-2 mb-2"
                          v-if="entity.paciente.ficha_clinica.length > 0"
                        >
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">
                                    Descrição
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="fic in entity.paciente.ficha_clinica"
                                  :key="fic.ficha_clinica_id"
                                >
                                  <td>
                                    {{ fic.ficha_clinica_id }} -
                                    {{
                                      fic.avaliacao_clinica
                                        .tipo_avaliacao_clinica_nome
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mt-1">
                            <label
                              >Paciente depende de auxilio para se
                              locomover?</label
                            >
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.depende_auxilio"
                            >
                              <option value="SIM">Sim</option>
                              <option value="NÃO">Não</option>
                            </select>
                          </div>
                          <div
                            class="col-md-3 mt-1"
                            v-if="entity.depende_auxilio == 'SIM'"
                          >
                            <label>Qual?</label>
                            <select
                              disabled
                              class="form-control"
                              v-model="entity.tipo_auxilio_locomocao_id"
                            >
                              <option
                                v-for="tipo in tipos_auxilio_locomocao"
                                :key="tipo.tipo_auxilio_locomocao_id"
                                :value="tipo.tipo_auxilio_locomocao_id"
                              >
                                {{ tipo.tipo_auxilio_locomocao_nome }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="col-md-3 mt-1"
                            v-if="entity.tipo_auxilio_locomocao_id == '6'"
                          >
                            <label
                              ><span class="required">*</span>
                              Especifique</label
                            >

                            <input
                              disabled
                              type="text"
                              class="form-control"
                              v-model="entity.outro_tipo_auxilio_locomocao"
                            />
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-12">
                            <h3>Antecedentes</h3>
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.hipertensao"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            Hipertensão
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.avc"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            AVC
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.etilismo"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            Etilismo
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.iam"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            IAM
                          </div>
                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.epilepsia"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            Epilepsia
                          </div>
                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.diabetes"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            Diabetes
                          </div>

                          <div class="col-md-3 mt-1">
                            <toggle-button
                              v-model="entity.paciente.asma"
                              color="#15488A"
                              :height="25"
                              disabled
                            />
                            Asma
                          </div>

                          <div class="col-md-6 mt-1">
                            <label for="outros">Outros: </label>
                            <input
                              type="text"
                              class="form-control"
                              id="outros"
                              v-model="entity.paciente.outros_antecedentes"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">CID's</div>
                          </div>
                        </div>

                        <div class="row" v-if="entity.cids.length > 0">
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 100%">
                                    Código e Nome
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="cid in entity.cids"
                                  :key="cid.cid_id"
                                >
                                  <td>
                                    {{ cid.cid.cid_codigo }} -
                                    {{ cid.cid.cid_nome }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row" v-else>
                          <div class="col-md-12">Nenhum CID informado</div>
                        </div>
                      </section>

                      <section
                        v-if="entity.tipo_transporte.tipo_solicitacao != 1"
                      >
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Avaliações Clínicas do Paciente
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mb-3"
                          v-if="
                            ($store.state.user.perfil_id == 2 ||
                              $store.state.user.perfil_id == 6 ||
                              $store.state.user.perfil_id == 21) &&
                            (entity.status_solicitacao == 1 ||
                              entity.status_solicitacao == 2)
                          "
                        >
                          <div class="col-md-12">
                            <textarea
                              v-model="avaliacao_clinica"
                              class="form-control"
                              id=""
                              cols="20"
                              rows="3"
                            ></textarea>
                          </div>
                          <div class="col-md-12 mt-1 text-right">
                            <button
                              class="btn btn-primary"
                              @click="incluirAvaliacao"
                            >
                              <i class="fas fa-plus"></i> Incluir Avaliação
                            </button>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="entity.avaliacoes_clinicas.length > 0"
                        >
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 52%">
                                    Avaliação
                                  </th>
                                  <th scope="col" style="width: 25%">
                                    Usuário
                                  </th>
                                  <th scope="col" style="width: 23%">
                                    Horário
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="avaliacao in entity.avaliacoes_clinicas"
                                  :key="avaliacao.data_registro_f"
                                >
                                  <td>
                                    {{ avaliacao.avaliacao_clinica_descricao }}
                                  </td>
                                  <td>{{ avaliacao.usuario.usuario_nome }}</td>
                                  <td>{{ avaliacao.data_registro_f }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="row" v-else>
                          <div class="col-md-12">
                            Nenhuma Avaliação Clínica cadastrada
                          </div>
                        </div>
                      </section>
                      <section>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Intercorrências</div>
                          </div>
                        </div>
                        <div
                          class="row mb-3"
                          v-if="
                            ($store.state.user.perfil_id == 2 ||
                              $store.state.user.perfil_id == 21 ||
                              $store.state.user.perfil_id == 7) &&
                            (entity.status_solicitacao == 1 ||
                              entity.status_solicitacao == 2)
                          "
                        >
                          <div
                            class="col-md-12"
                            v-if="
                              entity.responsavel_programacao_id ===
                                $store.state.user.usuario_id ||
                              ($store.state.user.perfil_id == 2 &&
                                entity.status_solicitacao == 2)
                            "
                          >
                            <textarea
                              v-model="intercorrencia"
                              class="form-control"
                              id=""
                              cols="20"
                              rows="3"
                            ></textarea>
                          </div>
                          <div
                            class="col-md-12 mt-1 text-right"
                            v-if="
                              entity.responsavel_programacao_id ===
                                $store.state.user.usuario_id ||
                              ($store.state.user.perfil_id == 2 &&
                                entity.status_solicitacao == 2)
                            "
                          >
                            <button
                              class="btn btn-primary"
                              @click="incluirIntercorrencia"
                            >
                              <i class="fas fa-plus"></i> Incluir Intercorrência
                            </button>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="entity.intercorrencias.length > 0"
                        >
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 52%">
                                    Intercorrência
                                  </th>
                                  <th scope="col" style="width: 25%">
                                    Usuário
                                  </th>
                                  <th scope="col" style="width: 23%">
                                    Horário
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="intercorrencia in entity.intercorrencias"
                                  :key="intercorrencia.data_registro_f"
                                >
                                  <td>
                                    {{
                                      intercorrencia.intercorrencia_descricao
                                    }}
                                  </td>
                                  <td>
                                    {{ intercorrencia.usuario.usuario_nome }}
                                  </td>
                                  <td>{{ intercorrencia.data_registro_f }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="row" v-else>
                          <div class="col-md-12">
                            Nenhuma Intercorrência cadastrada
                          </div>
                        </div>
                      </section>
                      <section>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">Desfecho</div>
                          </div>
                        </div>
                        <div
                          class="row mb-3"
                          v-if="
                            $store.state.user.perfil_id == 7 ||
                            ($store.state.user.perfil_id == 2 &&
                              entity.status_solicitacao == 2) ||
                            entity.status_solicitacao == 3
                          "
                        >
                          <div class="col-md-12">
                            <v-select
                              :options="tipo_desfechos"
                              :reduce="(tipo_desfechos) => tipo_desfechos.id"
                              :label="'tipo_desfecho'"
                              v-model="desfecho.tipo_desfecho_id"
                              class="vselect"
                            />
                            <textarea
                              v-model="desfecho.desfecho_descricao"
                              class="form-control mt-1"
                              id=""
                              cols="20"
                              rows="3"
                            ></textarea>
                          </div>
                          <div
                            class="col-md-12 mt-1 text-right"
                            v-if="
                              entity.responsavel_programacao_id ===
                                $store.state.user.usuario_id ||
                              $store.state.user.perfil_id == 2
                            "
                          >
                            <button
                              class="btn btn-primary"
                              @click="incluirDesfecho"
                            >
                              <i class="fas fa-plus"></i> Incluir Desfecho
                            </button>
                          </div>
                        </div>
                        <div class="row" v-if="entity.desfechos.length > 0">
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 52%">
                                    Tipo do Desfecho
                                  </th>
                                  <th scope="col" style="width: 52%">
                                    Desfecho
                                  </th>
                                  <th scope="col" style="width: 25%">
                                    Usuário
                                  </th>
                                  <th scope="col" style="width: 23%">
                                    Horário
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="desfecho in entity.desfechos"
                                  :key="desfecho.created_at_f"
                                >
                                  <td>
                                    {{
                                      desfecho.tipo_desfecho
                                        ? desfecho.tipo_desfecho.tipo_desfecho
                                        : ""
                                    }}
                                  </td>
                                  <td>
                                    {{ desfecho.desfecho_descricao }}
                                  </td>
                                  <td>
                                    {{
                                      desfecho.usuario
                                        ? desfecho.usuario.usuario_nome
                                        : ""
                                    }}
                                  </td>
                                  <td>{{ desfecho.created_at_f }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="row" v-else>
                          <div class="col-md-12">
                            Nenhum desfecho cadastrado
                          </div>
                        </div>
                      </section>
                      <section>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Logs de Visualização no App do Condutor
                            </div>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="logs.length > 0 && usuarioAbriuSolicitacao()"
                        >
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 50%">
                                    Mensagem
                                  </th>
                                  <th scope="col" style="width: 20%">
                                    Horário
                                  </th>
                                  <th scope="col" style="width: 20%">
                                    Usuário
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="log in logs"
                                  :key="log.data_criacao_f"
                                >
                                  <td
                                    v-if="log.usuario_abriu_solicitacao != null"
                                  >
                                    {{ log.mensagem }}
                                  </td>
                                  <td
                                    v-if="log.usuario_abriu_solicitacao != null"
                                  >
                                    {{
                                      log.data_criacao_f
                                        ? log.data_criacao_f
                                        : "Dados não disponíveis"
                                    }}
                                  </td>
                                  <td
                                    v-if="log.usuario_abriu_solicitacao != null"
                                  >
                                    {{
                                      log.usuario_abriu_solicitacao
                                        ? log.usuario_abriu_solicitacao
                                            .usuario_nome
                                        : "Dados não disponíveis"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row" v-else>
                          <div class="col-md-12">Nenhum Log cadastrado</div>
                        </div>
                      </section>

                      <section>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Logs de Criação e Edição da Solicitação
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="logs_json.length">
                          <div class="col-md-12">
                            <table
                              class="table table-bordered"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 40%">
                                    Detalhes
                                  </th>
                                  <th scope="col" style="width: 15%">
                                    Atualização
                                  </th>
                                  <th scope="col" style="width: 15%">
                                    Usuário
                                  </th>
                                  <th scope="col" style="width: 15%">Metódo</th>
                                  <th scope="col" style="width: 15%">Ações</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="log in logs_json" :key="log.id">
                                  <td>
                                    Origem:
                                    {{
                                      log
                                        ? log.json.origem.estabelecimento_nome
                                        : ""
                                    }}<br />
                                    Destino:
                                    {{
                                      log
                                        ? log.json.destino.estabelecimento_nome
                                        : ""
                                    }}
                                  </td>
                                  <td>
                                    {{ log.created_at_f }}
                                  </td>
                                  <td>
                                    {{ log ? log.json.usuario_nome : "" }}
                                  </td>
                                  <td>
                                    {{
                                      entity.usuario_solicitacao
                                        ? entity.usuario_solicitacao
                                            .usuario_nome
                                        : ""
                                    }}
                                  </td>

                                  <td>
                                    <button
                                      @click="showDetails(log)"
                                      class="btn btn-view"
                                    >
                                      Exibir JSON
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="row" v-else>
                          <div class="col-md-12">Nenhum Log cadastrado</div>
                        </div>
                        <b-modal
                          v-model="showModal"
                          title="Detalhes do JSON"
                          size="lg"
                        >
                          <pre>{{ selectedLogJson }}</pre>
                        </b-modal>
                      </section>

                      <section>
                        <div class="row mb-2 mt-3">
                          <div class="col-md-12">
                            <div class="b-card-subtitle">
                              Arquivos vinculados ao transporte
                            </div>
                          </div>
                        </div>
                        <div class="mt-1" v-if="anexosFiltrados.length > 0">
                          <table
                            class="table table-bordered"
                            style="width: 100%"
                          >
                            <thead>
                              <tr>
                                <th scope="col" style="width: 52%">
                                  Nome Arquivo
                                </th>
                                <th scope="col" style="width: 25%">Baixar</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="anexo in anexosFiltrados"
                                :key="anexo.id"
                              >
                                <td>
                                  {{ anexo.nome_anexo }}
                                </td>
                                <td>
                                  <a class="btn btn-add" :href="anexo.url"
                                    ><i class="fas fa-download"></i
                                  ></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row" v-else>
                          <div class="col-md-12">
                            Nenhum Arquivo Vinculado cadastrado
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button
          class="btn btn-edit"
          v-if="
            (entity.status_solicitacao == 0 &&
              $store.state.user.perfil_id == 2) ||
            $store.state.user.perfil_id == 6 ||
            $store.state.user.perfil_id == 21 ||
            $store.state.user.perfil_id == 5
          "
          @click="editar"
        >
          Editar
        </button>
        <button
          class="btn btn-approve"
          @click="approve"
          v-if="
            entity.status_solicitacao == 0 &&
            ($store.state.user.perfil_id == 2 ||
              $store.state.user.perfil_id == 6 ||
              $store.state.user.perfil_id == 5 ||
              $store.state.user.perfil_id == 21)
          "
        >
          Aprovar
        </button>
        <button
          class="btn btn-reprove"
          v-if="
            entity.status_solicitacao == 0 &&
            ($store.state.user.perfil_id == 2 ||
              $store.state.user.perfil_id == 6 ||
              $store.state.user.perfil_id == 21)
          "
          data-toggle="modal"
          data-target="#modalRejeitar"
        >
          Rejeitar
        </button>
        <button
          @click="getVeiculosDisponiveis"
          class="btn btn-info"
          v-if="
            entity.status_solicitacao == 2 &&
            (entity.responsavel_programacao_id ===
              $store.state.user.usuario_id ||
              $store.state.user.perfil_id == 2)
          "
          data-toggle="modal"
          data-target="#modalProgramacao"
        >
          Despachar Novo Veículo
        </button>
        <button
          class="btn btn-approve"
          v-if="
            entity.status_solicitacao == 2 &&
            ($store.state.user.perfil_id == 2 ||
              entity.responsavel_programacao_id ===
                $store.state.user.usuario_id)
          "
          @click="finalizar"
        >
          Finalizar
        </button>
        <button
          @click="getVeiculosDisponiveis"
          class="btn btn-approve"
          data-toggle="modal"
          data-target="#modalProgramacao"
          v-if="
            entity.status_solicitacao == 1 &&
            ($store.state.user.perfil_id == 2 ||
              $store.state.user.perfil_id == 7 ||
              $store.state.user.perfil_id == 6 ||
              $store.state.user.perfil_id == 21) &&
            (entity.responsavel_programacao_id === null ||
              entity.responsavel_programacao_id ===
                $store.state.user.usuario_id)
          "
        >
          Programar
        </button>
        <button
          class="btn btn-reprove"
          v-if="
            (entity.status_solicitacao == 1 ||
              entity.status_solicitacao == 2 ||
              entity.status_solicitacao == 3) &&
            ($store.state.user.perfil_id == 2 ||
              $store.state.user.perfil_id == 7 ||
              $store.state.user.perfil_id == 6 ||
              $store.state.user.perfil_id == 21) &&
            (entity.responsavel_programacao_id === null ||
              entity.responsavel_programacao_id ===
                $store.state.user.usuario_id)
          "
          data-toggle="modal"
          data-target="#modalRejeitar"
        >
          Cancelar
        </button>
        <button
          class="btn btn-abandonar"
          v-if="
            $store.state.user.perfil_id === 2 &&
            entity.responsavel_programacao_id !== null &&
            entity.status_solicitacao != 4
          "
          @click="removerResponsavel"
        >
          Remover o Responsável
        </button>
      </div>
    </div>

    <!-- Modal Prosseguir Programação -->
    <div
      class="modal fade"
      id="modalProsseguir"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalmodalProsseguirTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="alert alert-warning" role="alert">
                Ao clicar em "Sim", o contador será reiniciado, dando-lhe 15
                minutos para prosseguir com a programação. Caso o contador
                atinja 0, você será redirecionado para a tela de Programações.
                Optando por "Não" ou não realizando nenhuma ação, o contador
                permanecerá inalterado. Quando atingir 0, você será
                automaticamente redirecionado para a tela de Programações.
              </div>
              <div class="col-md-12 centered">
                <h5>Deseja prosseguir com a programação?</h5>
              </div>
              <button
                type="button"
                class="btn btn-primary mr-1"
                @click="prosseguirProgramacao(1)"
              >
                Sim
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Não
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="modalHistoricoMovimentacao"
      tabindex="-1"
      role="dialog"
      aria-labelledby="historicoMovimentacaoTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Histórico de Alterações da Solicitação
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="overflow-x: auto">
            <div
              class="row justify-content-center"
              v-if="logs.length > 0 && usuarioAlterouStatus()"
            >
              <div class="col-md-12">
                <table class="table table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 50%">Mensagem</th>
                      <th scope="col" style="width: 10%">Status Anterior</th>
                      <th scope="col" style="width: 10%">Status Atual</th>
                      <th scope="col" style="width: 5%">Horário</th>
                      <th scope="col" style="width: 10%">Usuário</th>
                      <th scope="col" style="width: 5%">
                        Data da Solicitação Anterior
                      </th>
                      <th scope="col" style="width: 5%">
                        Data da Solicitação Atual
                      </th>
                      <th scope="col" style="width: 5%">Usuário Edição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="log in logs" :key="log.data_criacao_f">
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{ log.mensagem }}
                      </td>
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{
                          log.status_solicitacao_anterior_f
                            ? log.status_solicitacao_anterior_f
                            : "Dados não disponíveis"
                        }}
                      </td>
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{
                          log.status_solicitacao_atual_f
                            ? log.status_solicitacao_atual_f
                            : "Dados não disponíveis"
                        }}
                      </td>
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{
                          log.data_criacao_f
                            ? log.data_criacao_f
                            : "Dados não disponíveis"
                        }}
                      </td>
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{
                          log.usuario_alteracao_status
                            ? log.usuario_alteracao_status.usuario_nome
                            : "Dados não disponíveis"
                        }}
                      </td>
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{
                          log.data_solicitacao_anterior_f
                            ? log.data_solicitacao_anterior_f
                            : "Dados não disponíveis"
                        }}
                      </td>
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{
                          log.data_solicitacao_alterada_f
                            ? log.data_solicitacao_alterada_f
                            : "Dados não disponíveis"
                        }}
                      </td>
                      <td v-if="log.usuario_abriu_solicitacao == null">
                        {{
                          log.usuario_edicao_id
                            ? log.usuario_edicao_solicitacao.usuario_nome
                            : "Dados não disponíveis"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">Nenhum Histórico cadastrado</div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Alterar Status -->
    <div
      class="modal fade"
      id="modalAlterar"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAlterarTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Alterar Status da Solicitação
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Status</label>
                <select class="form-control" id="statusSolicitacaoSelect">
                  <option :value="0">Solicitado</option>
                  <option :value="1">Aprovado</option>
                  <option :value="2">Programado</option>
                  <option :value="3" selected>Finalizado</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="alterarStatus"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Programação -->
    <div
      class="modal fade"
      id="modalProgramacao"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalProgramacaoTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Programar Transporte
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <label>Data do Procedimento</label>
                <input
                  disabled
                  type="datetime-local"
                  class="form-control"
                  v-model="entity.data_procedimento"
                />
              </div>
              <div class="col-md-4">
                <label>Tempo estimado de uso do veículo</label>
                <input
                  type="text"
                  v-mask="'99:99'"
                  class="form-control"
                  v-model="programacao.tempouso"
                  v-on:input="getVeiculosDisponiveis"
                />
              </div>
            </div>
            <div class="row mt-2" v-if="equipes_disponiveis.length > 0">
              <div class="col-md-12">
                <span>Selecione uma equipe para despachar</span>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6"
                v-for="equipe in equipes_disponiveis"
                :key="equipe.equipe_id"
              >
                <div class="form-check">
                  <input
                    v-model="equipe.value"
                    class="form-check-input disabled"
                    type="checkbox"
                    value="true"
                    :id="equipe.equipe_id"
                  />
                  <label
                    class="form-check-label"
                    :for="equipe.equipe_id"
                    style="font-size: 16px"
                  >
                    {{ equipe.equipe_nome }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="salvarProgramacao"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Lançar Movimento -->
    <div
      class="modal fade"
      id="modalLancamento"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLancamentoTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Lançar Movimento do Veículo
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-4">
                <label>Posição</label>
                <select class="form-control" v-model="movimentacao.tipo">
                  <option
                    v-for="tpos in tipos_posicao"
                    :key="tpos.id"
                    :value="tpos.posicao_veiculo_id"
                  >
                    {{ tpos.posicao_veiculo_nome }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Data/Hora</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="movimentacao.data"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="salvarMovimentacao"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Lista Movimentações -->
    <div
      class="modal fade"
      id="modalMovimentacoes"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalMovimentacoesTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Lista de Movimentações
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Posição</th>
                      <th scope="col">Data da Posição</th>
                      <th scope="col">Usuário</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="posicao in historico"
                      :key="posicao.posicao_veiculo_id"
                      v-bind:class="posicao.status == 0 ? 'deleted' : ''"
                    >
                      <th scope="row">
                        {{ posicao.id }}
                      </th>
                      <td>{{ posicao.posicao.posicao_veiculo_nome }}</td>
                      <td>{{ posicao.data_posicao_veiculo }}</td>
                      <td>{{ posicao.usuario.usuario_nome }}</td>

                      <td>
                        <button
                          class="btn btn-crud delete"
                          data-toggle="modal"
                          data-target="#modalHistorico"
                          @click="posicao_selecionada = posicao"
                          v-bind:disabled="posicao.status == 0 ? true : false"
                        >
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Lista Exclusao -->
    <div
      class="modal fade"
      id="modalDelecoes"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalDelecoesTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Histórico de Exclusão
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <p
                  v-for="veiculo in veiculos_enviados"
                  :key="veiculo.envio_veiculo_id"
                >
                  <span
                    style="font-size: 24px; margin-top: 10px"
                    v-if="veiculo.usuario_delecao"
                    ><strong
                      >Usuário:
                      {{ veiculo.usuario_delecao.usuario_nome }}</strong
                    ></span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Historico -->
    <div
      class="modal fade"
      id="modalHistorico"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalHistoricoTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Cancelar movimentação
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Justificativa</label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="10"
                  v-model="posicao_selecionada.justificativa_historico"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="deletePosition(id)"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Rejeitar -->
    <div
      class="modal fade"
      id="modalRejeitar"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalRejeitarTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Cancelar Transporte
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <label>Justificativa</label>
                <textarea
                  class="form-control"
                  cols="30"
                  rows="10"
                  v-model="entity.justificativa_cancelamento"
                ></textarea>
              </div>
              <div class="col-md-12">
                <label>Motivos de Cancelamentos </label>
                <v-select
                  :options="cancelamentos"
                  :reduce="(cancelamentos) => cancelamentos.id"
                  :label="'cancelamento_nome'"
                  v-model="entity.cancelamento_id"
                  class="vselect"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button type="button" class="btn btn-primary" @click="cancelar">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import html2pdf from "html2pdf.js";

export default {
  name: "imageUpload",
  data() {
    return {
      ras_url: "",
      minutos: 15,
      segundos: 0,
      oculta: false,
      configuracoes: {
        judicial: "",
        sinais_vitais: "",
        recorrencia: 0,
        tipo_destino: 0,
        dificuldade_acesso: 0,
      },
      desfecho: {
        tipo_desfecho_id: "",
        desfecho_descricao: "",
      },
      desfechos: [],
      conselho_classe: [
        {
          nome: "CRM",
          id: "1",
        },
        {
          nome: "CRF",
          id: "2",
        },
        {
          nome: "COREN",
          id: "3",
        },
        {
          nome: "CRO",
          id: "4",
        },
        {
          nome: "CRP",
          id: "5",
        },
        {
          nome: "RG",
          id: "7",
        },
        {
          nome: "Ass Social",
          id: "8",
        },
        {
          nome: "Não informado",
          id: "6",
        },
      ],
      moment: moment,
      isLoading: true,
      fullPage: true,
      page: 1,
      ufs: [],
      cbos: [],
      logs: [],
      logs_json: [],
      showModal: false,
      selectedLogJson: {},
      tipo_desfechos: [],
      tipos_cuidado: [],
      produtos: [],
      municipios_destino: [],
      municipios_origem: [],
      tipos_transporte: [],
      tipos_domicilio: [],
      tipos_avaliacao_clinica: [],
      tipos_auxilio_locomocao: [],
      tipos_acompanhante: [],
      tipos_posicao: [],
      motivo_transporte: [],
      equipes_disponiveis: [],
      unidade_saude: [],
      status: true,
      anexos: [],
      entity: {
        paciente: {
          endereco: "",
          bairro: "",
          tipo_peso: "KG",
          peso: "",
          altura: "",
          espontanea: "",
          verbal: "",
          motora: "",
        },
        acompanhante: {},
        solicitante: {},
        unidade: {},
        tipo_transporte: {},
        motivo_transporte: {},
        avaliacoes_clinicas: [],
        intercorrencias: [],
        cids: [],
        profissional: {},
        usuario_aprovacao: {
          usuario_nome: "",
        },
        classificacao: {},
      },

      avaliacao_clinica: "",
      intercorrencia: "",
      cid_selecionado: {},

      programacao: {
        tempouso: "00:00",
      },
      movimentacao: {
        veiculo: {
          posicoes: [],
        },
      },
      cids: [],
      cbos: [],
      veiculos_enviados: [],
      historico: [],
      cancelamentos: [],

      configuracoes: {
        judicial: "",
        sinais_vitais: "",
        recorrencia: 0,
        tipo_destino: 0,
      },
      posicao_selecionada: {},
    };
  },
  computed: {
    anexosFiltrados() {
      return this.anexos.filter(
        (anexo) =>
          anexo.transporte_sanitario_id === this.entity.transporte_sanitario_id
      );
    },
    diffIdadeDias() {
      const self = this;

      var dataNascimento = self.entity.paciente.data_nascimento;
      var dataNascimentoObj = new Date(dataNascimento);
      var dataAtual = new Date();
      var diferencaMilissegundos = dataAtual - dataNascimentoObj;
      var diferencaDias = diferencaMilissegundos / (1000 * 60 * 60 * 24);

      return diferencaDias;
    },
    imc() {
      var imc =
        this.entity.paciente.peso /
        (this.entity.paciente.altura * this.entity.paciente.altura);
      return Math.round(imc);
    },
    glasgowCount() {
      var returnVal =
        Number(this.entity.paciente.verbal) +
        Number(this.entity.paciente.motora) +
        Number(this.entity.paciente.espontanea);

      if (isNaN(returnVal)) {
        return "Não classificado";
      }
      return Math.round(returnVal);
    },
    glasgowLabel() {
      var string = "";

      switch (this.entity.paciente.verbal) {
        case "5":
          string += "<strong>Resposta verbal:</strong> Orientada ";
          break;
        case "4":
          string += "<strong>Resposta verbal:</strong> Confusa ";
          break;
        case "3":
          string += "<strong>Resposta verbal:</strong> Palavras inapropriadas ";
          break;
        case "2":
          string +=
            "<strong>Resposta verbal:</strong> Palavras incompreensivas ";
          break;
        case "1":
          string += "<strong>Resposta verbal:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.espontanea) {
        case "4":
          string += "<strong>Resposta ocular:</strong> Espontânea ";
          break;
        case "3":
          string += "<strong>Resposta ocular:</strong> À Voz ";
          break;
        case "2":
          string += "<strong>Resposta ocular:</strong> À Dor ";
          break;
        case "1":
          string += "<strong>Resposta ocular:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.motora) {
        case "6":
          string += "<strong>Resposta motora:</strong> Obedece comandos ";
          break;
        case "5":
          string += "<strong>Resposta motora:</strong> Localiza dor ";
          break;
        case "4":
          string += "<strong>Resposta motora:</strong> Movimento de retirada ";
          break;
        case "3":
          string += "<strong>Resposta motora:</strong> Flexão anormal ";
          break;
        case "2":
          string += "<strong>Resposta motora:</strong> Extensão anormal ";
          break;
        case "1":
          string += "<strong>Resposta motora:</strong> Nenhuma ";
          break;
        default:
          string += "";
      }

      return string;
    },

    glasgowLabelTxt() {
      var string = "";

      switch (this.entity.paciente.verbal) {
        case "5":
          string += "Resposta verbal: Orientada ";
          break;
        case "4":
          string += "Resposta verbal: Confusa ";
          break;
        case "3":
          string += "Resposta verbal: Palavras inapropriadas ";
          break;
        case "2":
          string += "Resposta verbal: Palavras incompreensivas ";
          break;
        case "1":
          string += "Resposta verbal: Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.espontanea) {
        case "4":
          string += "Resposta ocular: Espontânea ";
          break;
        case "3":
          string += "Resposta ocular: À Voz ";
          break;
        case "2":
          string += "Resposta ocular: À Dor ";
          break;
        case "1":
          string += "Resposta ocular: Nenhuma ";
          break;
        default:
          string += "";
      }

      switch (this.entity.paciente.motora) {
        case "6":
          string += "Resposta motora: Obedece comandos ";
          break;
        case "5":
          string += "Resposta motora: Localiza dor ";
          break;
        case "4":
          string += "Resposta motora: Movimento de retirada ";
          break;
        case "3":
          string += "Resposta motora: Flexão anormal ";
          break;
        case "2":
          string += "Resposta motora: Extensão anormal ";
          break;
        case "1":
          string += "Resposta motora: Nenhuma ";
          break;
        default:
          string += "";
      }

      return string;
    },
  },
  methods: {
    incluirIntercorrencia() {
      const self = this;
      let api = self.$store.state.api + "intercorrencia";

      self.$loading(true);

      if (self.intercorrencia == "") {
        self.$message(null, "Intercorrência, não pode ser vazia", "error");
        return;
      }

      var obj = {
        transporte_sanitario_id: self.entity.transporte_sanitario_id,
        usuario_id: self.$store.state.user.usuario_id,
        intercorrencia_descricao: self.intercorrencia,
      };

      axios
        .post(api, obj)
        .then((response) => {
          self.getEntity(self.entity.transporte_sanitario_id);
          self.intercorrencia = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    incluirAvaliacao() {
      const self = this;
      let api = self.$store.state.api + "avaliacao_clinica";

      self.$loading(true);

      if (self.avaliacao_clinica == "") {
        self.$message(
          null,
          "Avaliação clínica do paciente, não pode ser vazia",
          "error"
        );
        return;
      }

      var obj = {
        transporte_sanitario_id: self.entity.transporte_sanitario_id,
        usuario_id: self.$store.state.user.usuario_id,
        avaliacao_clinica_descricao: self.avaliacao_clinica,
      };

      axios
        .post(api, obj)
        .then((response) => {
          self.getEntity(self.entity.transporte_sanitario_id);
          self.avaliacao_clinica = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    incluirDesfecho() {
      const self = this;
      let api = self.$store.state.api + "desfechos";

      if (self.desfecho == "") {
        self.$message(
          null,
          "Desfecho da ocorrência não pode ser vazia",
          "error"
        );
        return;
      }

      var obj = {
        transporte_sanitario_id: self.entity.transporte_sanitario_id,
        usuario_id: self.$store.state.user.usuario_id,
        tipo_desfecho_id: self.desfecho.tipo_desfecho_id,
        desfecho_descricao: self.desfecho.desfecho_descricao,
      };

      axios
        .post(api, obj)
        .then((response) => {
          self.getEntity(self.entity.transporte_sanitario_id);
          self.desfecho.desfecho_descricao = "";
          self.desfecho.tipo_desfecho_id = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    exportToPDF() {
      const self = this;

      self.oculta = true;

      self.$loading(true);

      setTimeout(function () {
        var element = document.getElementById("element-to-convert");
        var opt = {
          margin: 0,
          filename: "FichaDeSolicitacao.pdf",
          image: { type: "png", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        };
        html2pdf().set(opt).from(element).save();
      }, 2000);

      setTimeout(function () {
        self.$loading(false);
        self.oculta = false;
      }, 5500);
    },

    deletarVeiculo(obj) {
      const self = this;

      swal({
        title: "Deseja deletar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi excluido!", "info");
            break;

          case "confirm":
            let api = self.$store.state.api + "envio_veiculo/deletar";
            axios
              .post(api, obj)
              .then((response) => {
                swal("Sucesso", "Registro excluído com sucesso!", "success");
                self.getEnvioVeiculo(self.entity.id);
                setTimeout(function () {
                  self.getEntity(self.entity.id);
                }, 1000);
              })
              .catch((error) => {
                swal(
                  "Erro",
                  "Este registro não pode ser deletado, pois já está sendo utilizado no sistema!",
                  "error"
                );
              });
            break;
        }
      });
    },
    editar() {
      const self = this;
      self.$router.push(
        "/solicitacoes/edit/" + self.entity.transporte_sanitario_id
      );
    },
    getConfiguracoes: function () {
      const self = this;
      let api = self.$store.state.api + "configuracoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.configuracoes = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getLogsJson() {
      const self = this;

      let api =
        self.$store.state.api +
        "logs_json?transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id;

      axios
        .get(api)
        .then((response) => {
          self.logs_json = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    showDetails(log) {
      this.selectedLogJson = log.json;
      this.showModal = true;
    },
    setHistorico(posicoes) {
      this.historico = posicoes;
    },
    iniciarProgramacao() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/iniciar-programacao";

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
        })
        .then((response) => {
          self.getEntity(self.entity.transporte_sanitario_id);
        })
        .catch((error) => {
          self.getEntity(self.entity.transporte_sanitario_id);
          self.$message(null, error.response.data, "error");
        });
    },
    mFDRas: function () {
      const self = this;
      let fd = new FormData();
      let anexoRas = document.getElementById("rasPic");
      fd.append("transporte_sanitario_id", self.entity.transporte_sanitario_id);
      fd.append("ras", anexoRas.files[0] ? anexoRas.files[0] : "");
      anexoRas.value = "";
      return fd;
    },
    salvarRas: function () {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/salvar_ras";
      let fd = self.mFDRas();
      self.$http
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getEntity(self.entity.transporte_sanitario_id);
          self.ras = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getEntity: function (id) {
      const self = this;

      self.$loading(true);
      let api =
        self.$store.state.api +
        "solicitacoes/" +
        id +
        "?with[]=paciente&with[]=solicitante&with[]=unidade&with[]=avaliacoes_clinicas&with[]=desfechos&with[]=cids&with[]=tipo_transporte&with[]=motivo_transporte&with[]=acompanhante&with[]=origem&with[]=destino&with[]=cancelamento&with[]=usuario_aprovacao&with[]=classificacao&with[]=usuario_cancelamento&with[]=usuario_solicitacao&with[]=usuario_programacao&with[]=usuario_finalizacao&with[]=intercorrencias&with[]=responsavel_programacao&with[]=usuario_rih";

      axios
        .get(api)
        .then((response) => {
          self.entity = response.data.data[0];

          if (
            self.$store.state.user.perfil_id == 2 ||
            self.$store.state.user.perfil_id == 7
          ) {
            self.getLogVisualizacoes();
            self.getLogsJson();
          }

          if (
            self.entity.responsavel_programacao_id == null &&
            (self.$store.state.user.perfil_id == 6 ||
              self.$store.state.user.perfil_id == 7)
          ) {
            self.iniciarProgramacao();
          }

          if (self.entity.flag_retorna_origem == "0") {
            self.entity.flag_retorna_origem = false;
          } else {
            self.entity.flag_retorna_origem = true;
          }

          if (self.entity.tipo_transporte.tipo_solicitacao == 0) {
            if (
              self.entity.paciente.incubadora == 0 ||
              self.entity.paciente.incubadora == "0" ||
              self.entity.paciente.incubadora == null
            ) {
              self.entity.paciente.incubadora = false;
            } else {
              self.entity.paciente.incubadora = true;
            }

            if (
              self.entity.paciente.bomba_infusora == null ||
              self.entity.paciente.bomba_infusora == "0" ||
              self.entity.paciente.bomba_infusora == 0
            ) {
              self.entity.paciente.flg_bomba_infusora = false;
            } else {
              self.entity.paciente.flg_bomba_infusora = true;
            }

            if (
              self.entity.paciente.alergias == null ||
              self.entity.paciente.alergias == "0" ||
              self.entity.paciente.alergias == 0
            ) {
              self.entity.paciente.flg_alergias = false;
            } else {
              self.entity.paciente.flg_alergias = true;
            }

            if (
              self.entity.flag_mandado_judicial == 0 ||
              self.entity.flag_mandado_judicial == "0" ||
              self.entity.flag_mandado_judicial == null
            ) {
              self.entity.flag_mandado_judicial = false;
            } else {
              self.entity.flag_mandado_judicial = true;
            }

            if (
              self.entity.flag_vaga_zero == 0 ||
              self.entity.flag_vaga_zero == "0" ||
              self.entity.flag_vaga_zero == null
            ) {
              self.entity.flag_vaga_zero = false;
            } else {
              self.entity.flag_vaga_zero = true;
            }

            if (
              self.entity.flag_medico_unidade == 0 ||
              self.entity.flag_medico_unidade == "0" ||
              self.entity.flag_medico_unidade == null
            ) {
              self.entity.flag_medico_unidade = false;
            } else {
              self.entity.flag_medico_unidade = true;
            }

            // if (
            //   self.entity.paciente.covid == 0 ||
            //   self.entity.paciente.covid == null ||
            //   self.entity.paciente.covid == "0"
            // ) {
            //   self.entity.paciente.covid = false;
            // } else {
            //   self.entity.paciente.covid = true;
            // }

            if (
              self.entity.paciente.transporte_bariatrico == 0 ||
              self.entity.paciente.transporte_bariatrico == null ||
              self.entity.paciente.transporte_bariatrico == "0"
            ) {
              self.entity.paciente.transporte_bariatrico = false;
            } else {
              self.entity.paciente.transporte_bariatrico = true;
            }

            if (
              self.entity.paciente.hipertensao == 0 ||
              self.entity.paciente.hipertensao == null ||
              self.entity.paciente.hipertensao == "0"
            ) {
              self.entity.paciente.hipertensao = false;
            } else {
              self.entity.paciente.hipertensao = true;
            }

            if (
              self.entity.paciente.epilepsia == 0 ||
              self.entity.paciente.epilepsia == null ||
              self.entity.paciente.epilepsia == "0"
            ) {
              self.entity.paciente.epilepsia = false;
            } else {
              self.entity.paciente.epilepsia = true;
            }

            if (
              self.entity.paciente.avc == 0 ||
              self.entity.paciente.avc == null ||
              self.entity.paciente.avc == "0"
            ) {
              self.entity.paciente.avc = false;
            } else {
              self.entity.paciente.avc = true;
            }

            if (
              self.entity.paciente.diabetes == 0 ||
              self.entity.paciente.diabetes == null ||
              self.entity.paciente.diabetes == "0"
            ) {
              self.entity.paciente.diabetes = false;
            } else {
              self.entity.paciente.diabetes = true;
            }

            if (
              self.entity.paciente.etilismo == 0 ||
              self.entity.paciente.etilismo == null ||
              self.entity.paciente.etilismo == "0"
            ) {
              self.entity.paciente.etilismo = false;
            } else {
              self.entity.paciente.etilismo = true;
            }

            if (
              self.entity.paciente.asma == 0 ||
              self.entity.paciente.asma == null ||
              self.entity.paciente.asma == "0"
            ) {
              self.entity.paciente.asma = false;
            } else {
              self.entity.paciente.asma = true;
            }

            if (
              self.entity.paciente.iam == 0 ||
              self.entity.paciente.iam == null ||
              self.entity.paciente.iam == "0"
            ) {
              self.entity.paciente.iam = false;
            } else {
              self.entity.paciente.iam = true;
            }
          } else {
            self.getTiposCuidado();
            self.getMateriais();
            self.getCuidados();
            self.entity.material = {};
            self.entity.material.materiais = [];
          }

          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          self.getEstadoMunicipioOrigem();
          self.getEstadoMunicipioDestino();

          if (self.entity.status_solicitacao == 2) {
            self.iniciarContador();
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposCuidado: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_cuidado";

      if (self.tipos_cuidado.length != 0) {
        return;
      }

      axios
        .get(api)
        .then((response) => {
          self.tipos_cuidado = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTipoDesfechos: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_desfechos";

      axios
        .get(api)
        .then((response) => {
          self.tipo_desfechos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getMateriais() {
      const self = this;
      let api =
        self.$store.state.api +
        "transporte_sanitario_produtos?with[]=produto&paginated=false&transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id;

      axios
        .get(api)
        .then((response) => {
          var materiais = response.data.data;
          materiais.forEach((element) => {
            var obj = element.produto;
            self.entity.material.materiais.push(obj);
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCuidados() {
      const self = this;
      let api =
        self.$store.state.api +
        "ts_tipo_cuidados?with[]=tipo&paginated=false&transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id;

      axios
        .get(api)
        .then((response) => {
          var cuidados = response.data.data;

          cuidados.forEach((element) => {
            self.tipos_cuidado.forEach((tiac) => {
              if (tiac.tipo_cuidado_id == element.tipo_cuidado_id) {
                tiac.value = true;
              }
            });
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getLogVisualizacoes() {
      const self = this;
      let api =
        self.$store.state.api +
        "logs_genericos?transporte_sanitario_id=" +
        self.entity.transporte_sanitario_id +
        "&paginated=false&orderByDesc=data_criacao&with[]=usuario_alteracao_status&with[]=usuario_abriu_solicitacao&with[]=usuario_edicao_solicitacao";

      axios
        .get(api)
        .then((response) => {
          self.logs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioOrigem() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.origem.estado_id;

      axios
        .get(api)
        .then((response) => {
          self.municipios_origem = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEstadoMunicipioDestino() {
      const self = this;
      let api =
        self.$store.state.api +
        "municipios?orderByAsc=municipio_nome&paginated=false&uf_id=" +
        self.entity.destino.estado_id;
      axios
        .get(api)
        .then((response) => {
          self.municipios_destino = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAnexos() {
      const self = this;
      let api = self.$store.state.api + "condutor_anexos?paginated=false&";

      axios
        .get(api)
        .then((response) => {
          self.anexos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    removerResponsavel() {
      const self = this;

      let api = self.$store.state.api + "solicitacoes/abandonar-programacao";

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
        })
        .then((response) => {})
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    abandonarProgramacao() {
      const self = this;
      if (
        self.entity.responsavel_programacao_id ===
        self.$store.state.user.usuario_id
      ) {
        let api = self.$store.state.api + "solicitacoes/abandonar-programacao";

        axios
          .post(api, {
            transporte_sanitario_id: self.entity.transporte_sanitario_id,
          })
          .then((response) => {})
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      }
    },
    finalizar() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/finalizar";

      swal({
        title: "Deseja finalizar este transporte?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "A solicitação não foi finalizada!", "info");
            break;

          case "confirm":
            axios
              .post(api, {
                transporte_sanitario_id: self.entity.transporte_sanitario_id,
              })
              .then((response) => {
                swal("Sucesso!", "A solicitação foi finalizada!", "success");
                self.$router.push("/programacoes");
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
              });

            break;
        }
      });
    },
    pararContador() {
      clearInterval(this.intervalId);
      this.contadorEmExecucao = false;
    },
    atualizarContador() {
      if (
        this.minutos === 0 &&
        this.segundos === 0 &&
        this.entity.status_solicitacao == 2
      ) {
        this.pararContador();
        $("#modalProsseguir").modal("hide");
        this.$router.push("/programacoes");
      } else {
        if (this.segundos === 0) {
          this.minutos--;
          this.segundos = 59;
        } else {
          this.segundos--;
        }
      }
      if (
        this.minutos === 1 &&
        this.segundos === 0 &&
        this.entity.prosseguir_programacao_status != 1 &&
        this.entity.status_solicitacao == 2
      ) {
        this.prosseguirProgramacao();
      }
    },
    iniciarContador() {
      if (!this.contadorEmExecucao) {
        this.intervalId = setInterval(() => {
          this.atualizarContador();
        }, 1000);

        this.contadorEmExecucao = true;
      }
    },
    prosseguirProgramacao(prosseguir_programacao_status) {
      const self = this;
      const api = self.$store.state.api + "solicitacoes/prosseguir-programacao";

      if (
        this.minutos === 1 &&
        this.segundos === 0 &&
        this.entity.status_solicitacao == 2
      ) {
        setTimeout(function () {
          $("#modalProsseguir").modal("show");
        }, 500);
      } else {
        swal({
          title: "Deseja prosseguir com a programação?",
          text: "Essa operação não pode ser desfeita",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Sim, prosseguir!",
          showLoaderOnConfirm: true,
          buttons: {
            catch: {
              text: "Não",
              value: "cancel",
              className: "btn-danger",
            },
            confirm: {
              text: "Sim",
              value: "confirm",
            },
          },
        }).then((value) => {
          switch (value) {
            case "cancel":
              swal("Cancelado", "Programação não prosseguiu!", "info");
              break;

            case "confirm":
              const novoStatus = prosseguir_programacao_status;

              axios
                .post(api, {
                  transporte_sanitario_id: self.entity.transporte_sanitario_id,
                  prosseguir_programacao_status: novoStatus,
                })
                .then((response) => {
                  $("#modalProsseguir").modal("hide");
                  self.$loading(false);
                  swal("Sucesso!", "A programação prosseguiu!", "success");
                  setTimeout(function () {
                    if (novoStatus === 1) {
                      self.resetContador();
                    }
                    self.getEntity(self.entity.id);
                  }, 1000);
                })
                .catch((error) => {
                  self.$loading(false);
                  self.$message(null, error.response.data, "error");
                });
              break;
          }
        });
      }
    },
    resetContador() {
      this.minutos = 15;
      this.segundos = 0;
    },
    usuarioAlterouStatus() {
      for (let i = 0; i < this.logs.length; i++) {
        if (this.logs[i].usuario_abriu_solicitacao == null) {
          return true;
        }
      }
      return false;
    },
    usuarioAbriuSolicitacao() {
      for (let i = 0; i < this.logs.length; i++) {
        if (this.logs[i].usuario_abriu_solicitacao != null) {
          return true;
        }
      }
      return false;
    },
    alterarStatus() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/alterar-status";

      swal({
        title: "Deseja alterar status?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O status não foi alterado!", "info");
            break;

          case "confirm":
            const selectElement = document.getElementById(
              "statusSolicitacaoSelect"
            );
            const novoStatus = parseInt(selectElement.value, 10);
            axios
              .post(api, {
                transporte_sanitario_id: self.entity.transporte_sanitario_id,
                status_solicitacao: novoStatus,
              })
              .then((response) => {
                $("#modalAlterar").modal("hide");
                self.$loading(false);
                swal("Sucesso!", "O status foi alterado!", "success");
                setTimeout(function () {
                  self.getEntity(self.entity.id);
                }, 1000);
              })
              .catch((error) => {
                self.$loading(false);
                self.$message(null, error.response.data, "error");
              });

            break;
        }
      });
    },

    getProfissional: function () {
      const self = this;
      let api = self.$store.state.api + "profissionais?with[]=cbo";

      axios
        .get(api)
        .then((response) => {
          self.profissional = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getCbo: function () {
      const self = this;
      let api = self.$store.state.api + "cbos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cbos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    approve() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/aprovacao";

      self.$loading(true);

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
        })
        .then((response) => {
          self.$router.push("/aprovacoes");
          self.$loading(false);
          swal("Sucesso!", "A solicitação foi aprovada!", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    cancelar() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/cancelar";

      self.$loading(true);

      axios
        .post(api, {
          transporte_sanitario_id: self.entity.transporte_sanitario_id,
          justificativa: self.entity.justificativa_cancelamento,
          cancelamento_id: self.entity.cancelamento_id,
        })
        .then((response) => {
          $("#modalRejeitar").modal("hide");
          self.$router.push("/solicitacoes");
          self.$loading(false);
          swal("Sucesso!", "A solicitação foi cancelada!", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    salvarProgramacao() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/programacao";

      self.programacao.transporte_sanitario_id =
        self.entity.transporte_sanitario_id;
      self.programacao.data_procedimento = self.entity.data_procedimento;
      self.programacao.equipes_disponiveis = self.equipes_disponiveis;

      axios
        .post(api, self.programacao)
        .then((response) => {
          self.getEntity(self.entity.id);
          self.getEnvioVeiculo(self.entity.id);

          $("#modalProgramacao").modal("hide");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    salvarMovimentacao() {
      const self = this;
      let api = self.$store.state.api + "solicitacoes/movimentar_veiculo";

      self.$loading(true);

      axios
        .post(api, self.movimentacao)
        .then((response) => {
          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          $("#modalLancamento").modal("hide");
          self.movimentacao.tipo = "";
          self.movimentacao.data = "";
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getTiposAcompanhante: function (id) {
      const self = this;
      let api = self.$store.state.api + "tipo_acompanhante?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_acompanhante = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEnvioVeiculo: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "envio_veiculo?transporte_sanitario_id=" +
        id +
        "&with[]=veiculo&with[]=posicoes&with[]=usuario_delecao";

      axios
        .get(api)
        .then((response) => {
          self.veiculos_enviados = response.data.data;
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.isLoading = false;
        });
    },
    deletePosition: function () {
      const self = this;

      let api =
        self.$store.state.api +
        "movimentacao_veiculo/" +
        self.posicao_selecionada.id;

      self.$loading(true);
      axios
        .patch(api, {
          status: 0,
          justificativa_historico:
            self.posicao_selecionada.justificativa_historico,
        })
        .then((response) => {
          self.getEnvioVeiculo(self.entity.transporte_sanitario_id);
          self.$loading(false);
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.isLoading = false;
        });
    },

    selectUnidadeDestino() {
      const self = this;

      if (self.entity.destino.municipio_id == null) {
        return;
      }

      self.entity.destino.cep = self.entity.destino.unidade_saude_id.cep;
      self.entity.destino.endereco =
        self.entity.destino.unidade_saude_id.endereco;
      self.entity.destino.numero = self.entity.destino.unidade_saude_id.numero;
      self.entity.destino.ponto_refencia =
        self.entity.destino.unidade_saude_id.ponto_refencia;
      // self.entity.destino.complemento =
      //   self.entity.destino.unidade_saude_id.complemento;
      self.entity.destino.bairro = self.entity.destino.unidade_saude_id.bairro;
      self.entity.destino.estabelecimento_nome =
        self.entity.destino.unidade_saude_id.unidade_saude_nome;

      let api =
        self.$store.state.api +
        "municipios?municipio_id=" +
        self.entity.destino.unidade_saude_id.municipio_id;

      axios
        .get(api)
        .then((response) => {
          self.entity.destino.estado_id = response.data.data[0].uf_id;
          self.getEstadoMunicipioDestino();
          self.entity.destino.municipio_id = response.data.data[0].municipio_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposAuxilioLocomocao: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "tipo_auxilio_locomocao?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.tipos_auxilio_locomocao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCancelamentos: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "cancelamentos?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.cancelamentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposPosicao: function () {
      const self = this;
      let api =
        self.$store.state.api + "posicao_veiculo?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_posicao = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getVeiculosDisponiveis() {
      const self = this;
      let api = self.$store.state.api + "equipes_disponiveis";

      if (
        self.programacao.tempouso.includes("_") ||
        self.programacao.tempouso == ""
      ) {
        return;
      }

      self.programacao.transporte_sanitario_id =
        self.entity.transporte_sanitario_id;
      self.programacao.data_procedimento = self.entity.data_procedimento;

      axios
        .post(api, self.programacao)
        .then((response) => {
          self.equipes_disponiveis = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTiposDomicilio: function () {
      const self = this;
      let api = self.$store.state.api + "tipo_domicilio";

      axios
        .get(api)
        .then((response) => {
          self.tipos_domicilio = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getConfiguracoes();
    //self.getProfissional();
    self.getTipoDesfechos();
    self.getAnexos();
    let id = self.$route.params.id;
    if (id) {
      self.change_password = false;
      self.getConfiguracoes();
      self.getTiposAcompanhante();
      self.getTiposAuxilioLocomocao();
      self.getTiposDomicilio();
      self.getTiposPosicao();
      self.getUFs();
      self.getCancelamentos();
      self.getEntity(id);
    }
    window.addEventListener("beforeunload", this.abandonarProgramacao());
  },
  beforeDestroy() {
    this.pararContador();
  },
  destroyed() {
    if (
      this.entity.responsavel_programacao_id ===
      this.$store.state.user.usuario_id
    ) {
      this.abandonarProgramacao();
      window.removeEventListener("beforeunload", this.abandonarProgramacao());
    }
    console.log("abandonado");
  },

  components: {
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.b-card-subtitle {
  font-size: 18px;
  font-weight: bold;
}

.btn-edit {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 60%;
  background-color: #3387f4;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-edit:hover {
  background-color: #2781f7;
  color: #fff;
  box-shadow: 5px 5px 7px #888;
}

.btn-info {
  position: fixed;
  width: 15%;
  /* height: 25px; */
  bottom: 40px;
  right: 60%;
  background-color: #5f9ef1;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #6a6a6a;
}

.btn-approve {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 45%;
  background-color: #25d366;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-approve:hover {
  background-color: #0daa47;
  color: #fff;
}

.btn-reprove {
  position: fixed;
  width: 10%;
  /* height: 25px; */
  bottom: 40px;
  right: 30%;
  background-color: #d3252e;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
}

.btn-reprove:hover {
  background-color: #af151d;
  color: #fff;
}

.btn-abandonar {
  position: fixed;
  width: auto;
  /* height: 25px; */
  bottom: 40px;
  right: 12%;
  background-color: #ffc222;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  box-shadow: 5px 5px 7px #888;
  display: inline-block;
}

.btn-abandonar:hover {
  background-color: #f3ae00;
  color: #fff;
}

/* progress2 Tracker v2 */

ol.progress2[data-steps="2"] li {
  width: 49% !important;
  z-index: 99;
}

ol.progress2[data-steps="3"] li {
  width: 33% !important;
  z-index: 99;
}

ol.progress2[data-steps="4"] li {
  width: 24% !important;
  z-index: 99;
}

ol.progress2[data-steps="5"] li {
  width: 19% !important;
  z-index: 99;
}

ol.progress2[data-steps="6"] li {
  width: 16% !important;
  z-index: 99;
}

ol.progress2[data-steps="7"] li {
  width: 14% !important;
  z-index: 99;
}

ol.progress2[data-steps="8"] li {
  width: 12% !important;
  z-index: 99;
}

ol.progress2[data-steps="9"] li {
  width: 11% !important;
  z-index: 99;
}

.progress2 {
  width: 100% !important;
  list-style: none !important;
  list-style-image: none !important;
  margin: 20px 0 20px 0 !important;
  padding: 0 !important;
  z-index: 99;
}

.progress2 li {
  float: left !important;
  text-align: center !important;
  position: relative !important;
  z-index: 99;
}

.progress2 .name {
  display: block !important;
  text-align: center !important;
  margin-bottom: 1em !important;
  color: black !important;
  opacity: 0.3 !important;
  z-index: 99;
}

.progress2 .step {
  color: black !important;
  border: 3px solid silver !important;
  background-color: silver !important;
  border-radius: 50% !important;
  line-height: 1.5 !important;
  width: 2em !important;
  height: 2em !important;
  display: inline-block !important;
  z-index: 0 !important;
  font-weight: bold;
}

.progress2 .step-description {
  position: absolute;
  top: 80px;
  left: 30%;
}

.progress2 .step span {
  opacity: 0.3 !important;
}

.progress2 .active .name,
.progress2 .active .step span {
  opacity: 1 !important;
}

.progress2 .step:before {
  content: "" !important;
  display: block !important;
  background-color: silver !important;
  height: 0.4em !important;
  width: 50% !important;
  position: absolute !important;
  bottom: 0.6em !important;
  left: 0 !important;
  z-index: -1 !important;
}

.progress2 .step:after {
  content: "" !important;
  display: block !important;
  background-color: silver !important;
  height: 0.4em !important;
  width: 50% !important;
  position: absolute !important;
  bottom: 0.6em !important;
  right: 0 !important;
  z-index: -1 !important;
}

.progress2 li:first-of-type .step:before {
  display: none !important;
}

.progress2 li:last-of-type .step:after {
  display: none !important;
}

.progress2 .done .step,
.progress2 .done .step:before,
.progress2 .done .step:after,
.progress2 .active .step,
.progress2 .active .step:before {
  background-color: yellowgreen !important;
}

.progress2 .done .step,
.progress2 .active .step {
  border: 3px solid yellowgreen !important;
}

.deleted {
  text-decoration: line-through;
  color: #a1a1a1;
}

tr.obesidade1 {
  color: #fff;
  font-weight: bold;
  background-color: #ff4500;
}

tr.obesidade2 {
  color: #fff;
  font-weight: bold;
  background-color: #ff0000;
}

tr.obesidade3 {
  color: #fff;
  font-weight: bold;
  background-color: #e30505;
}

.classificacao {
  width: 35px;
  height: 35px;
  border-radius: 3px;
}

.break {
  page-break-before: always;
}

@media print {
  .no-print {
    display: none;
  }
}

.pisca {
  animation: animate 2.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.riscar {
  text-decoration: line-through;
  color: red;
}

.table-lg {
  width: 120%;
}
</style>
