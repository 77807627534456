let env = {};

env.mode = 'homolog';


env.baseURL = 'https://api.tsa.ares-saude.com.br/api/v1/';
env.normalURL = 'https://api.tsa.ares-saude.com.br/';

export default env

